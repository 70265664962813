import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import moment from 'moment';
import { DefaultScenarios, YearScenario } from '../../resources/macro-graph/macro-graph-types';
import { parseNumberFromString } from '../macro-graph/editable-yrMo-table/utils';

export interface iFormatedData {
  [key: string]: any;
  id: string;
  country: string;
}

const cellRender = (
  { value, rowIndex }: { value: string; rowIndex: number },
  editedCells: { [key: string]: boolean },
  appliedCells: { [key: string]: boolean },
  columnId: string,
) => {
  let className = editedCells[`${rowIndex}-${columnId}`] ? 'edited-cell' : '';
  className += appliedCells[`${rowIndex}-${columnId}`] ? ' applied-cell' : '';

  return <div className={className}>{value}</div>;
};

export const scenarioForecastTableHeaders = (
  rawRows: DefaultScenarios,
  editedCells: { [key: string]: boolean },
  appliedCells: { [key: string]: boolean },
  lastYear: number,
): TypeColumn[] => {
  const totalYears = rawRows.ana.length;
  return [
    { defaultLocked: true, name: 'id', header: 'Id', defaultVisible: false, defaultWidth: 100, type: 'number' },
    {
      name: 'country',
      header: 'Country',
      editable: false,
    },
    ...Array.from({ length: totalYears })
      .map((_, index) => {
        const year = rawRows.ana[index].year;
        const isActualData = year < moment().year();

        let headers = [
          isActualData
            ? {
                name: `${year}-forecast`,
                header: `${year} - Sale $`,
                editable: false,
                render: (props: { value: string; rowIndex: number }) =>
                  cellRender(props, editedCells, appliedCells, `${year}-forecast`),
                style: { backgroundColor: '#f7f7f7' },
              }
            : {
                name: `${year}-forecast`,
                header: `${year} - MAT forecast`,
                editable: false,
                render: (props: { value: string; rowIndex: number }) =>
                  cellRender(props, editedCells, appliedCells, `${year}-forecast`),
                style: { backgroundColor: '#e4e3e3' },
              },
        ];

        if (index > 0) {
          headers = [
            ...headers,
            {
              name: `${year}-yoy`,
              header: `${year} - YoY%`,
              editable: false,
              render: (props: { value: string; rowIndex: number }) =>
                cellRender(props, editedCells, appliedCells, `${year}-yoy`),
              style: { backgroundColor: '#f7f7f7' },
            },
          ];
        }
        if (!isActualData) {
          headers = [
            ...headers,
            {
              name: `${year}-ro`,
              header: `${year} - R&O`,
              editable: true,
              render: (props: { value: string; rowIndex: number }) =>
                cellRender(props, editedCells, appliedCells, `${year}-ro`),
              style: { backgroundColor: '#d0e6f9' },
            },
            {
              name: `${year}-roYoY`,
              header: `${year} - YoY% (+R&O)`,
              editable: true,
              render: (props: { value: string; rowIndex: number }) =>
                cellRender(props, editedCells, appliedCells, `${year}-roYoY`),
              style: { backgroundColor: '#f7f7f7' },
            },
          ];
        }

        return headers;
      })
      .flat(),
    {
      name: 'fc-ro-last',
      header: `Summary - ${lastYear} (FC + R&O)`,
      editable: false,
      render: (props: { value: string; rowIndex: number }) =>
        cellRender(props, editedCells, appliedCells, `fc-ro-last`),
      style: { backgroundColor: '#e4e3e3' },
    },
    {
      name: 'three-cagr-perc',
      header: `${totalYears - 2} yr CAGR%`,
      editable: false,
      render: (props: { value: string; rowIndex: number }) =>
        cellRender(props, editedCells, appliedCells, `three-cagr-perc`),
      style: { backgroundColor: '#f7f7f7' },
    },
    {
      name: 'three-cagr-perc-ro',
      header: `${totalYears - 2} yr CAGR % R&O`,
      editable: false,
      render: (props: { value: string; rowIndex: number }) =>
        cellRender(props, editedCells, appliedCells, 'three-cagr-perc-ro'),
      style: { backgroundColor: '#f7f7f7' },
    },
  ];
};

export const formatRows = (
  rawRows: DefaultScenarios,
): {
  id: string;
  country: string;
  [key: string]: any;
}[] => {
  const totalYears = rawRows.ana.length;
  return [
    ...Object.entries(rawRows).map(([row, yearScenario]: [string, YearScenario[]], index) => {
      return {
        id: `${row}`,
        country: camelCaseToTitleCase(row),
        ...Array.from({ length: totalYears })
          .map((_, index) => {
            const year = yearScenario[index].year;
            const currentYearScenario = yearScenario[index];
            return {
              [`${year}-forecast`]: Number(currentYearScenario.total.toFixed(0)).toLocaleString(),
              [`${year}-yoy`]: Number(currentYearScenario.yoy.toFixed(1)).toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }),
              [`${year}-ro`]: Number(currentYearScenario.ro.toFixed(0)).toLocaleString(),
              [`${year}-roYoY`]: Number(currentYearScenario.roYoy.toFixed(1)).toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }),
            };
          })
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        ['fc-ro-last']: Number(calculateLastYearFCRo(yearScenario).toFixed(0)).toLocaleString(),
        ['three-cagr-perc']: Number(calculateCagr(yearScenario).toFixed(1)).toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }),
        ['three-cagr-perc-ro']: Number(calculateCagrRo(yearScenario).toFixed(1)).toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }),
      };
    }),
  ];
};

const calculateLastYearFCRo = (yearScenario: YearScenario[]) => {
  return yearScenario[yearScenario.length - 1].total + yearScenario[yearScenario.length - 1].ro;
};

const calculateCagr = (yearScenario: YearScenario[]) => {
  const divider = yearScenario[1].total;
  return getCagrResult(yearScenario[yearScenario.length - 1].total, divider);
};

export const getCagrResult = (numerator: number, denominator: number) => {
  if (denominator != 0) {
    const temp = numerator / denominator;
    if (temp > 0) return (temp ** (1 / 3) - 1) * 100;
  }

  return 0;
};

const calculateCagrRo = (yearScenario: YearScenario[]) => {
  const divider = yearScenario[1].total;
  return getCagrResult(calculateLastYearFCRo(yearScenario), divider);
};

const camelCaseToTitleCase = (str: string) => {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
};

export const getNewTotal = (
  formatedData: iFormatedData[],
  oldValue: number,
  newValue: number,
  columnYear: number,
  type: string,
) => {
  const oldTotal = parseNumberFromString(formatedData[formatedData.length - 1][`${columnYear}-${type}`]);
  const newTotal = oldTotal - oldValue + newValue;

  const oldTotalExChina = parseNumberFromString(formatedData[formatedData.length - 2][`${columnYear}-${type}`]);
  const newTotalExChina = oldTotalExChina - oldValue + newValue;

  return [newTotal, newTotalExChina];
};

export const getNewTotalRoYoYAverage = (formatedData: iFormatedData[], columnYear: number) => {
  const newRoYoY = getNewTotalRoYoY(formatedData, columnYear, formatedData.length - 1);
  const newRoYoYExChina = getNewTotalRoYoY(formatedData, columnYear, formatedData.length - 2);
  return [newRoYoY, newRoYoYExChina];
};

const getNewTotalRoYoY = (formatedData: iFormatedData[], columnYear: number, rowIndex: number) => {
  const currentYearValue = parseNumberFromString(formatedData[rowIndex][`${columnYear - 1}-forecast`]);
  const currentYearRo = parseNumberFromString(formatedData[rowIndex][`${columnYear - 1}-ro`]);

  const nextYearForecastValue = parseNumberFromString(formatedData[rowIndex][`${columnYear}-forecast`]);
  const nextYearRoValue = parseNumberFromString(formatedData[rowIndex][`${columnYear}-ro`]);

  if (currentYearValue + currentYearRo == 0) {
    return 0;
  }

  return ((nextYearForecastValue + nextYearRoValue) / (currentYearValue + currentYearRo) - 1) * 100;
};
