import { Button, Typography } from '@amway/react-components';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { NormalAlert } from '../../../components/core/alert-card/alert-cards';
import useCopyShortcut from '../../../components/custom-hooks/useCopyShortcut';
import { CurrencyAdminTableResponse, EditedValue } from '../../../resources/currency-admin/currency-admin-types';
import currencyAdminService from '../../../resources/currency-admin/currency-admin.service';
import { corpReferHeaders, FormatedRow, formatRows } from '../utils';

interface Props {
  data: CurrencyAdminTableResponse;
  onResetData: () => void;
}

export default function CurrencyAdminTable({ data, onResetData }: Props) {
  const [gridInstance, setGridInstance] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);
  const [gridInvertedInstance, setGridInvertedInstance] = useState<MutableRefObject<TypeComputedProps | null> | null>(
    null,
  );

  const [editedValues, setEditedValues] = useState<EditedValue[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [formattedData, setFormattedData] = useState<FormatedRow[]>(formatRows(data.rows, false));
  const [formattedInvertedData, setFormattedInvertedData] = useState<FormatedRow[]>(
    formatRows(data.rowsInverted, true),
  );

  const [selectedUsdToLocalCells, setSelectedUsdToLocalCells] = useState<Record<string, boolean>>({});
  const [selectedLocalToUsdCells, setSelectedLocalToUsdCells] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setFormattedData(formatRows(data.rows, false));
    setFormattedInvertedData(formatRows(data.rowsInverted, true));
  }, [data]);

  const minHeight = useMemo(() => {
    return (Math.min(Number(data.rows.length), formattedData.length) + 1) * 40 + 4;
  }, [data.rows.length, formattedData.length]);

  const onEditStart = useCallback(
    ({ columnId, rowIndex }: any, isInverted: boolean) => {
      if (!data.isEditable) {
        if (isInverted) gridInvertedInstance?.current?.cancelEdit?.({ columnId, rowIndex });
        else gridInstance?.current?.cancelEdit?.({ columnId, rowIndex });
        return false;
      }

      isEditingCell.current = true;
    },
    [data.isEditable, gridInstance, gridInvertedInstance],
  );

  const onEditComplete = useCallback(
    async ({ value, columnId, rowIndex }: any, isInverted: boolean) => {
      if (isInverted) {
        if (value === formattedInvertedData[rowIndex][columnId]) return;
      } else {
        if (value === formattedData[rowIndex][columnId]) return;
      }

      const corpCd = formattedData[rowIndex].corp;

      const finalValue = Number(value);
      const invertedValue = finalValue != 0 ? 1 / finalValue : 0;

      const editedValue = {
        countryCode: corpCd,
        exchangeRateType: data.exchangeRateType,
        yearMonth: Number(columnId),
        newValue: isInverted ? invertedValue : finalValue,
      };

      setEditedValues(prev => [...prev, editedValue]);

      formattedData[rowIndex][columnId] = isInverted ? invertedValue : finalValue;
      setFormattedData(formattedData);
      formattedInvertedData[rowIndex][columnId] = isInverted ? finalValue : invertedValue;
      setFormattedInvertedData(formattedInvertedData);

      isEditingCell.current = false;
    },

    [data.exchangeRateType, formattedData, formattedInvertedData],
  );

  const onUpdateValues = useCallback(async () => {
    setIsUpdating(true);
    try {
      await currencyAdminService.editValues(editedValues);
      NormalAlert('Exchange rate edits saved successfully');
    } catch {
      NormalAlert('Error when saving exchange rate edits');
    }
    setEditedValues([]);
    setIsUpdating(false);
  }, [editedValues]);

  const isEditingCell = useRef(false);
  // Function to handle key down event
  const handleKeyDown = useCallback(
    (event: any) => {
      if (
        (event.key === '1' ||
          event.key === '2' ||
          event.key === '3' ||
          event.key === '4' ||
          event.key === '5' ||
          event.key === '6' ||
          event.key === '7' ||
          event.key === '8' ||
          event.key === '9' ||
          event.key === '0') &&
        !isEditingCell.current &&
        !!gridInstance?.current
      ) {
        if (selectedUsdToLocalCells && Object.keys(selectedUsdToLocalCells).length === 1) {
          const cellId = Object.entries(selectedUsdToLocalCells)?.[0][0];
          const [countryCd, year] = cellId.split(',');

          if (!formattedData) {
            return;
          }

          if (gridInstance.current.startEdit && isEditingCell.current === false) {
            const value = event.key === 'Enter' ? undefined : event.key;
            gridInstance?.current?.startEdit?.({ rowIndex: 0, columnId: year, value });
            isEditingCell.current = true;
          }
        } else if (selectedLocalToUsdCells && Object.keys(selectedLocalToUsdCells).length === 1) {
          const cellId = Object.entries(selectedLocalToUsdCells)?.[0][0];
          const [countryCd, year] = cellId.split(',');

          if (!formattedInvertedData) {
            return;
          }

          if (gridInvertedInstance?.current?.startEdit && isEditingCell.current === false) {
            const value = event.key === 'Enter' ? undefined : event.key;
            gridInvertedInstance?.current?.startEdit?.({ rowIndex: 0, columnId: year, value });
            isEditingCell.current = true;
          }
        }
      }
    },
    [
      formattedData,
      formattedInvertedData,
      gridInstance,
      gridInvertedInstance,
      selectedLocalToUsdCells,
      selectedUsdToLocalCells,
    ],
  );

  // Attach the event listener once the grid is ready
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const formatSelectedCellsForClipboard = useCallback(() => {
    const selectedRows: string[][] = [];

    let selectedCells;
    let rows: FormatedRow[];

    if (Object.keys(selectedUsdToLocalCells).length > 0) {
      selectedCells = selectedUsdToLocalCells;
      rows = formattedData;
    } else if (Object.keys(selectedLocalToUsdCells).length > 0) {
      selectedCells = selectedLocalToUsdCells;
      rows = formattedInvertedData;
    } else {
      return '';
    }

    console.log(selectedCells, rows);

    Object.keys(selectedCells).forEach(key => {
      const [countryCd, yr] = key.split(',');
      const rowId = yr;
      const rowIndex = 0; // Only one line exists in the grid
      const value = rows[rowIndex][rowId];

      // Create a new row if it doesn't exist
      if (!selectedRows[rowIndex]) {
        selectedRows[rowIndex] = [];
      }

      selectedRows[rowIndex].push(value);
    });

    const result = selectedRows.map(row => row.join('\t')).join('\n');

    return result;
  }, [formattedData, formattedInvertedData, selectedLocalToUsdCells, selectedUsdToLocalCells]);

  useCopyShortcut(formatSelectedCellsForClipboard());

  return (
    <>
      <Row>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: 15, marginBottom: 25 }}>
          <Button
            disabled={isUpdating || editedValues.length < 1}
            loading={isUpdating}
            backgroundColor="warning-success"
            onClick={onUpdateValues}>
            Update
          </Button>
          <Button variant="link" fontColor="warning-error" onClick={onResetData}>
            Discard changes
          </Button>
        </div>
      </Row>
      <Row>
        <Typography variant="subheading">USD to one Local Currency Unit</Typography>
        <Row>
          <ReactDataGrid
            idProperty="id"
            style={{ height: '100%', minHeight: `${minHeight}px` }}
            reorderColumns={false}
            dataSource={formattedData}
            columns={corpReferHeaders(editedValues, data.columns)}
            editable={data.isEditable}
            onEditStart={param => {
              onEditStart(param, false);
            }}
            onEditComplete={param => {
              onEditComplete(param, false);
            }}
            showColumnMenuLockOptions={false}
            showColumnMenuGroupOptions={false}
            showColumnMenuFilterOptions={false}
            showGroupSummaryRow={false}
            onReady={setGridInstance}
            cellSelection={selectedUsdToLocalCells}
            onCellSelectionChange={(selectedCells: Record<string, boolean>) => {
              setSelectedUsdToLocalCells(selectedCells);
              setSelectedLocalToUsdCells({});
            }}
            onEditCancel={() => {
              isEditingCell.current = false;
            }}
            onEditStop={() => {
              isEditingCell.current = false;
            }}
          />
        </Row>
      </Row>
      <Row>
        <Typography variant="subheading">Local Currency Units to 1 USD</Typography>
        <Row>
          <ReactDataGrid
            idProperty="id"
            style={{ height: '100%', minHeight: `${minHeight}px` }}
            reorderColumns={false}
            dataSource={formattedInvertedData}
            columns={corpReferHeaders(editedValues, data.columns)}
            editable={data.isEditable}
            onEditStart={param => {
              onEditStart(param, true);
            }}
            onEditComplete={param => {
              onEditComplete(param, true);
            }}
            showColumnMenuLockOptions={false}
            showColumnMenuGroupOptions={false}
            showColumnMenuFilterOptions={false}
            showGroupSummaryRow={false}
            onReady={setGridInvertedInstance}
            cellSelection={selectedLocalToUsdCells}
            onCellSelectionChange={(selectedCells: Record<string, boolean>) => {
              setSelectedLocalToUsdCells(selectedCells);
              setSelectedUsdToLocalCells({});
            }}
            onEditCancel={() => {
              isEditingCell.current = false;
            }}
            onEditStop={() => {
              isEditingCell.current = false;
            }}
          />
        </Row>
      </Row>
    </>
  );
}
