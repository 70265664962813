import { useCallback, useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import MaintenanceContext from './maintenance-context';
import { FilterDataTypesReq, MaintenanceTableResponse } from './maintenance-types';
import maintenanceService from './maintenance.service';

const MaintenanceProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [maintenanceTableRows, setMaintenanceTableRows] = useState<ExternalData<MaintenanceTableResponse>>(
    makeExternalDataInitialData(),
  );

  const fetchMaintenanceTableRows = useCallback(async (req: FilterDataTypesReq) => {
    setMaintenanceTableRows(makeExternalDataInitialData());

    const { promise, abort } = maintenanceService.filterDataTypes(req);

    try {
      const res = await promise;
      setMaintenanceTableRows(makeExternalDataSuccessData(res));
    } catch (e: any) {
      setMaintenanceTableRows(makeExternalCallErrorData(e));
    }

    return abort;
  }, []);

  return (
    <MaintenanceContext.Provider
      value={{
        fetchMaintenanceTableRows,
        maintenanceTableRows,
      }}>
      {children}
    </MaintenanceContext.Provider>
  );
};

export default MaintenanceProvider;
