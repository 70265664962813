import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import SummaryReportContext from './ranking-report-context';
import { GetRankingReportRequest } from './ranking-report-types';
import RankingReportService from './ranking-report.service';

const SummaryReportProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const downloadRankingReport = useCallback(async (request: GetRankingReportRequest) => {
    const { promise, abort } = RankingReportService.downloadRankingReport(request);

    try {
      const res = await promise;
      const fileUrl = window.URL.createObjectURL(new Blob([res.data]));

      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', request.reportName);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(fileUrl);
    } catch (error: any) {
      const errorBlob = error.response.data;
      const errorText = await errorBlob.text();
      NormalAlert(JSON.parse(errorText).error || 'Error when trying to create the report');
    }
  }, []);

  const sendToEmailRankingReport = useCallback(async (request: GetRankingReportRequest) => {
    const { promise, abort } = RankingReportService.sendToEmailRankingReport(request);

    try {
      const res = await promise;
      NormalAlert('You will receive your email soon');
    } catch (error: any) {
      const axiosError = error as AxiosError;
      if (axiosError.response) NormalAlert(axiosError.response.data.error);
      else NormalAlert('Error when trying to send the email');
    }
  }, []);

  return (
    <SummaryReportContext.Provider
      value={{
        downloadRankingReport,
        sendToEmailRankingReport,
      }}>
      {children}
    </SummaryReportContext.Provider>
  );
};

export default SummaryReportProvider;
