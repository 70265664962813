import { Button, Typography } from '@amway/react-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import CorpCodeSelector from '../../components/core/multi-select-checkbox-corpcode-input';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import useCommon from '../../resources/common/common-hook';
import useMaintenance from '../../resources/maintenance/maintenance-hook';
import CorpReferTable from './corp-refer-table';
import { formatDate } from './utils';

export default function MaintenanceScreen() {
  const [initialFetch, setInitialFetch] = useState<boolean>(false);
  const { countries, fetchAllCountries } = useCommon();
  const { fetchMaintenanceTableRows, maintenanceTableRows } = useMaintenance();
  const [forecastStartYearMonth, setForecastStartYearMonth] = useState<string>(
    moment().subtract(1, 'years').format('YYYY-MM'),
  );
  const [forecastEndYearMonth, setForecastEndYearMonth] = useState<string>(moment().format('YYYY-MM'));
  const [corpCodesSelected, setCorpCodesSelected] = useState<string[]>([]);

  useEffect(() => {
    if (countries.length === 0) fetchAllCountries();
  }, []);

  const handleApplyButton = () => {
    if (corpCodesSelected.length === 0) {
      NormalAlert('Select the country code');
      return;
    }

    setInitialFetch(true);
    fetchMaintenanceTableRows({
      countryCodes: corpCodesSelected.join(','),
      startDt: formatDate(forecastStartYearMonth),
      endDt: formatDate(forecastEndYearMonth),
    });
  };

  return (
    <Container>
      <Card>
        <Card.Body>
          <Row>
            <Typography variant="heading">Maintenance</Typography>
            <Typography weight="bold" color="text-gray">
              Here you can modify data types directly{' '}
            </Typography>
          </Row>
          <Row className="mt-5">
            <Typography weight="bold">Filter</Typography>
          </Row>
          <Row className="align-items-end">
            <Col>
              <Typography fontSize="10px" color="detail-gray">
                Corp Code
              </Typography>
              <CorpCodeSelector
                selectedCorpCodes={corpCodesSelected}
                setCorpCodesSelected={setCorpCodesSelected}
                clear={true}
                multi={true}
              />
            </Col>
            <Col>
              <Typography fontSize="10px" color="detail-gray">
                Forecast Start Date
              </Typography>
              <Form.Control
                type="month"
                id="forecastStartDate"
                className="input"
                value={forecastStartYearMonth}
                onChange={e => setForecastStartYearMonth(e.target.value)}
              />
            </Col>
            <Col>
              <Typography fontSize="10px" color="detail-gray">
                Forecast End Date
              </Typography>
              <Form.Control
                type="month"
                id="forecastEndDate"
                className="input"
                value={forecastEndYearMonth}
                onChange={e => setForecastEndYearMonth(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col className="justify-content-end">
              <Button
                onClick={handleApplyButton}
                disabled={maintenanceTableRows.loading && initialFetch}
                loading={maintenanceTableRows.loading && initialFetch}>
                Apply
              </Button>
            </Col>
          </Row>
          {initialFetch && (
            <Row>
              <ExternalDataRenderer
                externalData={maintenanceTableRows}
                makeDataElement={data => <CorpReferTable data={data} onResetData={handleApplyButton} />}
              />
            </Row>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}
