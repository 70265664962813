import { Button } from '@amway/react-components';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import { ScenarioForecastResponse } from '../../../resources/macro-graph/macro-graph-types';
import { parseNumberFromString } from '../../macro-graph/editable-yrMo-table/utils';
import {
  formatRows,
  getCagrResult,
  getNewTotal,
  getNewTotalRoYoYAverage,
  scenarioForecastTableHeaders,
} from '../utils';

interface Props {
  data: ScenarioForecastResponse;
  finalYear: number;
}

export default function ScenarioForecastTable({ data, finalYear }: Props) {
  const gridInstance = React.useRef<any>(null);
  const [editedCells, setEditedCells] = useState({});
  const [appliedCells, setAppliedCells] = useState({});

  const [resetChanges, setResetChanges] = useState(false);
  const [formatedData, setFormatedData] = useState(formatRows(data.allScenarios));

  useEffect(() => {
    setFormatedData(formatRows(data.allScenarios));
  }, [data, resetChanges]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(formatedData);
    const currentRowCount = formatedData.length + 1;
    XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: `A${currentRowCount + 1}` });

    const summaryData = [
      ['Summary', ''],
      ['Scenario', '$'],
      ['vs. HQ Forecast', `$ ${Number(hqForecast.toFixed(0)).toLocaleString()}`],
      [`HQ FC vs. ${moment().year() - 1}`, `$ ${Number(vsLastYear.toFixed(0)).toLocaleString()}`],
      [
        `${data.allScenarios.ana.length - 2} Yr CAGR %`,
        `${formatedData[formatedData.length - 1]['three-cagr-perc-ro']}%`,
      ],
      [
        `HQ ${data.allScenarios.ana.length - 2} Yr CAGR %`,
        `${formatedData[formatedData.length - 1]['three-cagr-perc']}%`,
      ],
    ];

    XLSX.utils.sheet_add_aoa(worksheet, summaryData, { origin: `B${currentRowCount + 2}` });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Scenario Forecast.xlsx');
  };

  const adjustYoYRoColumnForNextYear = useCallback(
    (columnYearEdited: number, rowIndex: number, newAppliedCells: { [key: string]: any }) => {
      const totalLine = formatedData.length - 1;
      const totalExChinaLine = formatedData.length - 2;

      const currentYearValue = parseNumberFromString(formatedData[rowIndex][`${columnYearEdited}-forecast`]);
      const currentYearRo = parseNumberFromString(formatedData[rowIndex][`${columnYearEdited}-ro`]);

      const nextYearForecastValue = parseNumberFromString(formatedData[rowIndex][`${columnYearEdited + 1}-forecast`]);
      const nextYearRoValue = parseNumberFromString(formatedData[rowIndex][`${columnYearEdited + 1}-ro`]);

      const nextYearNewYoyRoValue =
        ((nextYearForecastValue + nextYearRoValue) / (currentYearValue + currentYearRo) - 1) * 100;

      newAppliedCells[`${rowIndex}-${columnYearEdited + 1}-roYoY`] = true;
      formatedData[rowIndex][`${columnYearEdited + 1}-roYoY`] = Number(nextYearNewYoyRoValue.toFixed(1)).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        },
      );

      const [newRoYoYAverage, newRoYoYAverageExChina] = getNewTotalRoYoYAverage(formatedData, columnYearEdited + 1);

      newAppliedCells[`${totalLine}-${columnYearEdited + 1}-roYoY`] = true;
      formatedData[totalLine][`${columnYearEdited + 1}-roYoY`] = Number(newRoYoYAverage.toFixed(1)).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        },
      );
      if (rowIndex !== 0) {
        newAppliedCells[`${totalExChinaLine}-${columnYearEdited + 1}-roYoY`] = true;
        formatedData[totalExChinaLine][`${columnYearEdited + 1}-roYoY`] = Number(
          newRoYoYAverageExChina.toFixed(1),
        ).toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        });
      }
    },
    [formatedData],
  );

  const adjustSummaryColumnsRow = useCallback(
    (rowIndex: number, newAppliedCells: { [key: string]: any }) => {
      const firstYear = moment().year() - 2;

      const lastYearValue = parseNumberFromString(formatedData[rowIndex][`${finalYear}-forecast`]);
      const lastYearValueWithRo = lastYearValue + parseNumberFromString(formatedData[rowIndex][`${finalYear}-ro`]);

      const divider = parseNumberFromString(formatedData[rowIndex][`${firstYear + 1}-forecast`]);

      formatedData[rowIndex][`fc-ro-last`] = Number(lastYearValueWithRo.toFixed(0)).toLocaleString();

      formatedData[rowIndex][`three-cagr-perc`] =
        divider != 0
          ? Number(getCagrResult(lastYearValue, divider).toFixed(1)).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : 0;

      formatedData[rowIndex][`three-cagr-perc-ro`] =
        divider != 0
          ? Number(getCagrResult(lastYearValueWithRo, divider).toFixed(1)).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : 0;

      newAppliedCells[`${rowIndex}-fc-ro-last`] = true;
      newAppliedCells[`${rowIndex}-three-cagr-perc-ro`] = true;
    },
    [finalYear, formatedData],
  );

  const calculateValuesPostEdition = useCallback(
    (columnYear: number, rowIndex: number, oldRo: number, newRo: number, oldRoYoY: number, newRoYoY: number) => {
      let newAppliedCells = {};

      const [newRoTotal, newRoTotalExChina] = getNewTotal(formatedData, oldRo, newRo, columnYear, 'ro');
      formatedData[formatedData.length - 1][`${columnYear}-ro`] = Number(newRoTotal.toFixed(0)).toLocaleString();

      if (columnYear !== finalYear) adjustYoYRoColumnForNextYear(columnYear, rowIndex, newAppliedCells);

      const [newRoYoYAverage, newRoYoYAverageExChina] = getNewTotalRoYoYAverage(formatedData, columnYear);
      formatedData[formatedData.length - 1][`${columnYear}-roYoY`] = Number(newRoYoYAverage.toFixed(1)).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        },
      );

      newAppliedCells = {
        ...newAppliedCells,
        [`${formatedData.length - 1}-${columnYear}-ro`]: true,
        [`${formatedData.length - 1}-${columnYear}-roYoY`]: true,
      };

      if (rowIndex !== 0) {
        formatedData[formatedData.length - 2][`${columnYear}-ro`] = Number(
          newRoTotalExChina.toFixed(0),
        ).toLocaleString();
        formatedData[formatedData.length - 2][`${columnYear}-roYoY`] = Number(
          newRoYoYAverageExChina.toFixed(1),
        ).toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        });
        newAppliedCells = {
          ...newAppliedCells,
          [`${formatedData.length - 2}-${columnYear}-ro`]: true,
          [`${formatedData.length - 2}-${columnYear}-roYoY`]: true,
        };
      }

      if (columnYear >= finalYear) {
        adjustSummaryColumnsRow(rowIndex, newAppliedCells);
        adjustSummaryColumnsRow(formatedData.length - 1, newAppliedCells);
        if (rowIndex !== 0) {
          adjustSummaryColumnsRow(formatedData.length - 2, newAppliedCells);
        }
      }

      return newAppliedCells;
    },
    [adjustSummaryColumnsRow, adjustYoYRoColumnForNextYear, finalYear, formatedData],
  );

  const onEditStart = useCallback(
    ({ columnId, rowIndex }: any) => {
      if (rowIndex > formatedData.length - 3) {
        gridInstance.current.cancelEdit({ columnId, rowIndex });
        return false;
      }
    },
    [formatedData.length],
  );

  const onEditComplete = useCallback(
    ({ value, columnId, rowIndex }: any) => {
      const newValue = parseNumberFromString(value === '' || !value ? '0' : value);
      if (newValue === parseNumberFromString(formatedData[rowIndex][columnId])) {
        return;
      }

      setEditedCells(prev => ({
        ...prev,
        [`${rowIndex}-${columnId}`]: true,
      }));

      const columnYear = Number(columnId.toString().split('-')[0]);
      const columnType = columnId.toString().split('-')[1].toString();

      const oldValue = parseNumberFromString(formatedData[rowIndex][columnId]);
      formatedData[rowIndex][columnId] =
        columnType === 'ro'
          ? Number(newValue.toFixed(0)).toLocaleString()
          : Number(newValue.toFixed(1)).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            });

      const currentForecast = parseNumberFromString(formatedData[rowIndex][`${columnYear}-forecast`]);
      const yearBeforeForecast = parseNumberFromString(formatedData[rowIndex][`${columnYear - 1}-forecast`]);
      const yearBeforeRo = parseNumberFromString(formatedData[rowIndex][`${columnYear - 1}-ro`]);

      if (columnType === 'ro') {
        const oldRoYoY = parseNumberFromString(formatedData[rowIndex][`${columnYear}-roYoY`]);
        const newRoYoY =
          yearBeforeForecast != 0 ? ((currentForecast + newValue) / (yearBeforeForecast + yearBeforeRo) - 1) * 100 : 0;
        formatedData[rowIndex][`${columnYear}-roYoY`] = parseNumberFromString(newRoYoY.toFixed(1)).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          },
        );

        let newAppliedCells = calculateValuesPostEdition(columnYear, rowIndex, oldValue, newValue, oldRoYoY, newRoYoY);
        setAppliedCells({ ...appliedCells, ...newAppliedCells, [`${rowIndex}-${columnYear}-roYoY`]: true });
      }
      if (columnType === 'roYoY') {
        const oldRo = parseNumberFromString(formatedData[rowIndex][`${columnYear}-ro`]);
        const newRo = (newValue / 100 + 1) * (yearBeforeForecast + yearBeforeRo) - currentForecast;
        formatedData[rowIndex][`${columnYear}-ro`] = parseNumberFromString(newRo.toFixed(0)).toLocaleString();

        let newAppliedCells = calculateValuesPostEdition(columnYear, rowIndex, oldRo, newRo, oldValue, newValue);
        setAppliedCells({ ...appliedCells, ...newAppliedCells, [`${rowIndex}-${columnYear}-ro`]: true });
      }
      setFormatedData(formatedData);
    },
    [formatedData, calculateValuesPostEdition, appliedCells],
  );

  const handleResetChanges = () => {
    setEditedCells({});
    setAppliedCells({});
    setResetChanges(!resetChanges);
  };

  const rowClassName = (index: any) => {
    if (index.props.rowIndex + 1 >= formatedData.length - 1) {
      return 'total-rows normal-rows';
    }
    return 'normal-rows';
  };

  const vsLastYear = useMemo(() => {
    return (
      parseNumberFromString(formatedData[formatedData.length - 1][`fc-ro-last`]) -
      parseNumberFromString(formatedData[formatedData.length - 1][`${moment().year() - 1}-forecast`])
    );
  }, [
    formatedData[formatedData.length - 1][`fc-ro-last`],
    formatedData[formatedData.length - 1][`${moment().year() - 1}-forecast`],
  ]);

  const hqForecast = useMemo(() => {
    return (
      parseNumberFromString(formatedData[formatedData.length - 1][`fc-ro-last`]) -
      parseNumberFromString(formatedData[formatedData.length - 1][`${finalYear}-forecast`])
    );
  }, [formatedData[formatedData.length - 1][`fc-ro-last`], data]);

  return (
    <>
      <div className="scenario-forecast-filter">
        <Button onClick={handleResetChanges} className="reset-scenario-forecast-changes">
          Reset changes
        </Button>
        <Button onClick={exportToExcel} className="reset-scenario-forecast-changes">
          Export data
        </Button>
      </div>
      <ReactDataGrid
        idProperty="id"
        className="scenario-forecast-table"
        showColumnMenuLockOptions={false}
        showColumnMenuTool={false}
        sortable={false}
        columns={scenarioForecastTableHeaders(data.allScenarios, editedCells, appliedCells, finalYear)}
        dataSource={formatedData}
        onEditStart={onEditStart}
        onEditComplete={onEditComplete}
        editable
        reorderColumns={false}
        style={{ height: 810 }}
        onReady={instance => {
          gridInstance.current = instance;
        }}
        rowClassName={rowClassName}
      />
      <table className="scenario-forecast-summary-table">
        <thead>
          <tr>
            <th colSpan={2} className="title">
              Summary
            </th>
          </tr>
          <tr>
            <th>Scenario</th>
            <th>$</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>vs. HQ Forecast</td>
            <td>$ {Number(hqForecast.toFixed(0)).toLocaleString()}</td>
          </tr>
          <tr>
            <td>HQ FC vs. {moment().year() - 1}</td>
            <td>$ {Number(vsLastYear.toFixed(0)).toLocaleString()}</td>
          </tr>
          <tr>
            <td>{data.allScenarios.ana.length - 2} Yr CAGR %</td>
            <td>{formatedData[formatedData.length - 1][`three-cagr-perc`]}%</td>
          </tr>
          <tr>
            <td>HQ {data.allScenarios.ana.length - 2} Yr CAGR %</td>
            <td>{formatedData[formatedData.length - 1]['three-cagr-perc-ro']}%</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
