import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import {
  IChartStyle,
  IMacroGraphRequest,
  IMacroGraphResponse,
  PersistValuesRequest,
  ScenarioForecastResponse,
} from './macro-graph-types';

export interface MacroGraphCtxType {
  graphTitle: string;
  graphSubtitle: string;
  setGraphTitle: (text: string) => void;
  setGraphSubtitle: (text: string) => void;
  chartRealTimeData: IMacroGraphResponse | undefined;
  updateRealTimeChartData: () => Promise<void>;
  setMacroGraphChartRealTimeData: React.Dispatch<React.SetStateAction<IMacroGraphResponse | undefined>>;
  tableRealTimeData: IMacroGraphResponse | undefined;
  updateRealTimeTableData: () => Promise<void>;
  setMacroGraphTableRealTimeData: React.Dispatch<React.SetStateAction<IMacroGraphResponse | undefined>>;
  persistValues: (req: PersistValuesRequest) => Promise<void>;
  graphData: ExternalData<IMacroGraphResponse>;
  filterGraph: (req: IMacroGraphRequest) => Promise<VoidFunction>;
  setChartStyle: React.Dispatch<React.SetStateAction<IChartStyle>>;
  chartStyle: IChartStyle;
  combinedSelectedCells: Record<number, Record<string, boolean>>;
  setCombinedSelectedCells: React.Dispatch<React.SetStateAction<Record<number, Record<string, boolean>>>>;
  cleanSelectedCells: () => void;
  changedScaleManually: boolean;
  setChangedScaleManually: React.Dispatch<React.SetStateAction<boolean>>;
  chartDataIsDirty: boolean;
  setChartDataIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  allDefaultScenarios: ExternalData<ScenarioForecastResponse>;
  fetchDefaultScenarios: (finalYear: number) => Promise<VoidFunction>;
  browserByCorpCodeCurrent: string;
  setBrowserByCorpCodeCurrent: React.Dispatch<React.SetStateAction<string>>;
  loadingRealTimeTableData: boolean;
  setLoadingRealTimeTableData: React.Dispatch<React.SetStateAction<boolean>>;
}

const MacroGraphContext = createContext<MacroGraphCtxType>(null as any);

export default MacroGraphContext;
