import DialogLayout from '../../../../../../components/core/dialog-layout';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function Show12MonthRollingAverageInfoModal({ isOpen, setIsOpen }: Props) {
  return (
    <DialogLayout
      open={isOpen}
      title="Show 12 Month Rolling Average Info"
      scapeActionText="Close"
      scapeActionHandler={() => {
        setIsOpen(false);
      }}>
      <p>
        "Show 12 Month Rolling Average" only refers to that year/line specifically, and a third line/table will be
        created as a result{' '}
      </p>
    </DialogLayout>
  );
}
