import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { NormalAlert } from '../../../components/core/alert-card/alert-cards';
import useCountryCodeAdmin from '../../../resources/country-code-admin/country-code-admin-hook';
import {
  CountryCodeAdminTree,
  CountryCodeRelationship,
} from '../../../resources/country-code-admin/country-code-admin-types';
import { convertToTreeData, getNewId } from '../util';
import './index.scss';

interface Props {
  show: boolean;
  currentData: CountryCodeAdminTree[];
  handleClose: () => void;
  handleSubmit: (country: CountryCodeRelationship) => void;
}

export function AddCountryForm({ show, currentData, handleClose, handleSubmit }: Props) {
  const { countryCodeAdminTree } = useCountryCodeAdmin();

  const treeData = useMemo(() => {
    return convertToTreeData(currentData);
  }, [currentData]);

  const [newCountry, setNewCountry] = useState<CountryCodeRelationship>({
    id: Number(getNewId(treeData)),
    name: '',
    countryCd: '',
    launchDate: null,
    parent: countryCodeAdminTree[0].id,
    order: 0,
    isToCreate: true,
    createUser: '',
    createDt: new Date(),
    updateUser: '',
    updateDt: new Date(),
  });

  useEffect(() => {
    setNewCountry({
      id: Number(getNewId(treeData)),
      name: '',
      countryCd: '',
      launchDate: null,
      parent: countryCodeAdminTree[0].id,
      order: 0,
      isToCreate: true,
      createUser: '',
      createDt: new Date(),
      updateUser: '',
      updateDt: new Date(),
    });
  }, [show]);

  const handleCreateButton = useCallback(() => {
    if (newCountry.name === '') {
      NormalAlert('The name cannot be empty');
      return;
    }
    if (newCountry.countryCd !== '') {
      const itemWithSameId = Object.values(treeData.items).find(
        item =>
          item.data &&
          item.data.countryCodeRelationship &&
          item.data.countryCodeRelationship.countryCd === newCountry.countryCd,
      );
      if (itemWithSameId) {
        NormalAlert('There is already a country with this country code');
        return;
      }
    }
    handleSubmit(newCountry);
  }, [newCountry, handleSubmit, treeData]);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Country/Market</Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-new-country-form">
          <Form>
            <Form.Group controlId="name" className="input">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={newCountry.name}
                onChange={e =>
                  setNewCountry(prev => {
                    return { ...prev, name: e.target.value };
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="code" className="input">
              <Form.Label>Country Cd</Form.Label>
              <Form.Control
                value={newCountry.countryCd}
                placeholder="If it's a market, just leave the field empty"
                onChange={e =>
                  setNewCountry(prev => {
                    return { ...prev, countryCd: e.target.value.trim().replace(/[^0-9]/g, '') };
                  })
                }
                maxLength={3}
              />
            </Form.Group>
            <Form.Group controlId="launchDate" className="input">
              <Form.Label>Launch Date</Form.Label>
              <input
                id="launchDate"
                type="date"
                value={moment(newCountry.launchDate).format('YYYY-MM-DD')}
                onChange={date =>
                  setNewCountry(prev => {
                    return { ...prev, launchDate: moment(date.target.value, 'YYYY-MM-DD').toDate() };
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateButton}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
