import { useCallback, useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import MaintenanceContext from './currency-admin-context';
import { CurrencyAdminRowsRequest, CurrencyAdminTableResponse } from './currency-admin-types';
import currencyAdminService from './currency-admin.service';

const MaintenanceProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [currencyAdminTableResponse, setCurrencyAdminTableResponse] = useState<
    ExternalData<CurrencyAdminTableResponse>
  >(makeExternalDataInitialData());

  const fetchCurrencyAdminTable = useCallback(async (req: CurrencyAdminRowsRequest) => {
    setCurrencyAdminTableResponse(makeExternalDataInitialData());

    const { promise, abort } = currencyAdminService.fetchCurrencyAdminTable(req);

    try {
      const res = await promise;
      setCurrencyAdminTableResponse(makeExternalDataSuccessData(res));
    } catch (e: any) {
      setCurrencyAdminTableResponse(makeExternalCallErrorData(e));
    }

    return abort;
  }, []);

  return (
    <MaintenanceContext.Provider
      value={{
        fetchCurrencyAdminTable,
        currencyAdminTableResponse,
      }}>
      {children}
    </MaintenanceContext.Provider>
  );
};

export default MaintenanceProvider;
