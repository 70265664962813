import { Typography } from '@amway/react-components';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import { HexColorPicker } from 'react-colorful';
import { NormalAlert } from '../../../../components/core/alert-card/alert-cards';
import DropdownBtn, { Item } from '../../../../components/ui/dropdownBtn';
import SideWrapper from '../../../../components/ui/side-wrapper';
import useMacroGraph from '../../../../resources/macro-graph/macro-graph-hook';
import useClickOutside from './custom-hook/useClickOutside';
import './index.scss';

interface Props {
  isToShow: boolean;
  close: () => void;
}

const stylesOptions: Item[] = [
  { id: 'trend', label: 'Trend (Extended)' },
  { id: 'seasonal', label: 'Seasonal (Overlaid)' },
];

const calendarsOptions: Item[] = [
  { id: 'calendar', label: 'Calendar (Jan-Dec)' },
  { id: 'fiscal', label: 'Fiscal (Sep-Aug)' },
  { id: 'line_date', label: 'Line Date' },
];

const ThemeTab: React.FC<Props> = ({ isToShow, close }) => {
  const popover = useRef<HTMLDivElement | null>(null);
  const [isColorPickerOpen, setColorPickerOpen] = useState<number | undefined>();

  const closeColorPicker = useCallback(() => setColorPickerOpen(undefined), []);
  useClickOutside(popover, closeColorPicker);

  const {
    chartStyle,
    setChartStyle,
    graphTitle,
    setGraphTitle,
    graphSubtitle,
    setGraphSubtitle,
    setChangedScaleManually,
  } = useMacroGraph();

  const lineIds = useMemo(
    () => chartStyle.lineConfigurations.map(config => config.lineId),
    [chartStyle.lineConfigurations],
  );

  const colorPickerPosition = (index: number) => {
    const top = 25 + 25 * Math.floor(index / 8);
    const left = 25 + 25 * (index % 8);
    return { top: `${top}px`, left: `${left}px` };
  };

  const linesLinesToCompareItems = useMemo(() => {
    return lineIds.map((lineId, index) => ({
      id: lineId,
      label: `Line ${index + 1}`,
    }));
  }, [lineIds]);

  const handleBrowseByCorpCodeCheckbox = (checked: boolean) => {
    if (checked) {
      if (linesLinesToCompareItems.length > 0) {
        setChartStyle(prev => ({
          ...prev,
          graphOptions: {
            ...prev.graphOptions,
            browseByCorpCode: true,
            browseByCorpCodeLine: linesLinesToCompareItems[0].id,
          },
        }));
      } else {
        NormalAlert("Fill in the information for at least one line to select it in the 'Browse by corp code' option.");
      }
    } else {
      setChartStyle(prev => ({
        ...prev,
        graphOptions: { ...prev.graphOptions, browseByCorpCode: false, browseByCorpCodeLine: 0 },
      }));
    }
  };

  useEffect(() => {
    if (lineIds.length > 0 && chartStyle.graphOptions.browseByCorpCode) {
      if (!lineIds.includes(chartStyle.graphOptions.browseByCorpCodeLine)) {
        setChartStyle(prev => ({
          ...prev,
          graphOptions: {
            ...prev.graphOptions,
            browseByCorpCodeLine: lineIds[0],
          },
        }));
      }
    } else {
      setChartStyle(prev => ({
        ...prev,
        graphOptions: { ...prev.graphOptions, browseByCorpCode: false, browseByCorpCodeLine: 0 },
      }));
    }
  }, [lineIds]);

  return (
    <SideWrapper title="Theme" isToShow={isToShow} close={close}>
      <div className="px-3 mt-4">
        <Row style={{ marginBottom: '12px' }}>
          <Col>
            <Typography fontSize="10px" color="detail-gray">
              Title
            </Typography>
            <Form.Control
              className="form-graph-title-input"
              onChange={e => {
                setGraphTitle(e.target.value);
              }}
              value={graphTitle}
              maxLength={100}
            />
          </Col>
          <Col>
            <Typography fontSize="10px" color="detail-gray">
              Subtitle
            </Typography>
            <Form.Control
              className="form-graph-title-input"
              onChange={e => {
                setGraphSubtitle(e.target.value);
              }}
              value={graphSubtitle}
              maxLength={200}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography fontSize="10px" color="detail-gray">
              Styles
            </Typography>
            <DropdownBtn
              id="choose-styles-dropdown"
              styleType="filter"
              placeholder="Select"
              items={stylesOptions}
              value={stylesOptions.find(opt => opt.id === chartStyle.styles)}
              onClick={item => {
                if (item) {
                  const value = item.id.toString();
                  if (value === 'seasonal') {
                    NormalAlert('Reminder: Seasonal (Overlaid) is only available for 1 line at a time');
                  }

                  setChartStyle(prev => {
                    return { ...prev, styles: value };
                  });
                }
              }}
            />
          </Col>
          <Col>
            <Typography fontSize="10px" color="detail-gray">
              Calendars
            </Typography>
            <DropdownBtn
              id="choose-calendars-dropdown"
              styleType="filter"
              placeholder="Select"
              items={calendarsOptions}
              value={calendarsOptions.find(opt => opt.id === chartStyle.calendars)}
              onClick={item => {
                if (item) {
                  const value = item.id.toString();
                  setChartStyle(prev => {
                    return { ...prev, calendars: value };
                  });
                }
              }}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Typography fontSize="10px" color="bg-black" weight="bold">
            Graph Options
          </Typography>
          <Col>
            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                control={<Checkbox />}
                label="Show Tabulation"
                checked={chartStyle.graphOptions.showTabulation}
                onChange={(_, checked) =>
                  setChartStyle(prev => ({ ...prev, graphOptions: { ...prev.graphOptions, showTabulation: checked } }))
                }
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Thick Lines"
                checked={chartStyle.graphOptions.thickLines}
                onChange={(_, checked) =>
                  setChartStyle(prev => ({ ...prev, graphOptions: { ...prev.graphOptions, thickLines: checked } }))
                }
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Browse by Corp"
                checked={chartStyle.graphOptions.browseByCorpCode}
                onChange={(_, checked) => {
                  handleBrowseByCorpCodeCheckbox(checked);
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                control={<Checkbox />}
                label="Scale Results"
                checked={chartStyle.graphOptions.scaleResults}
                onChange={(_, checked) => {
                  setChangedScaleManually(true);
                  setChartStyle(prev => ({ ...prev, graphOptions: { ...prev.graphOptions, scaleResults: checked } }));
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        {chartStyle.graphOptions.browseByCorpCode && linesLinesToCompareItems.length > 0 ? (
          <Row>
            <DropdownBtn
              id="choose-browse-by-corp-code-line"
              styleType="filter"
              placeholder="Select"
              items={linesLinesToCompareItems}
              value={linesLinesToCompareItems.find(item => item.id === chartStyle.graphOptions.browseByCorpCodeLine)}
              onClick={item => {
                if (item) {
                  setChartStyle(prev => ({
                    ...prev,
                    graphOptions: { ...prev.graphOptions, browseByCorpCodeLine: Number(item.id) },
                  }));
                }
              }}
            />
          </Row>
        ) : (
          <></>
        )}

        <Row className="mt-3">
          <Typography fontSize="10px" color="bg-black" weight="bold">
            Line Colors
          </Typography>
          <Row className="color-picker-container">
            {chartStyle.lineColors.map((style, index) => (
              <div
                key={index}
                className="color-picker-button"
                style={{ background: style.color }}
                onClick={() => setColorPickerOpen(index)}
              />
            ))}
            {isColorPickerOpen !== undefined && (
              <div className="popover" ref={popover} style={colorPickerPosition(isColorPickerOpen)}>
                <HexColorPicker
                  color={chartStyle.lineColors[isColorPickerOpen]?.color}
                  onChange={(newColor: string) =>
                    setChartStyle({
                      ...chartStyle,
                      lineColors: chartStyle?.lineColors?.map(
                        (style: { lineId: number; color: string }, index: number) =>
                          index === isColorPickerOpen ? { ...style, color: newColor } : style,
                      ),
                    })
                  }
                />
              </div>
            )}
          </Row>
        </Row>
      </div>
    </SideWrapper>
  );
};

export default ThemeTab;
