import { Typography } from '@amway/react-components';
import DialogLayout from '../../../../../../components/core/dialog-layout';
import useCommon from '../../../../../../resources/common/common-hook';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function DataTypesInfoModal({ isOpen, setIsOpen }: Props) {
  const { dataTypesMetadata } = useCommon();
  return (
    <DialogLayout
      open={isOpen}
      title="SALES DATA DEFINITIONS"
      scapeActionText="Close"
      scapeActionHandler={() => {
        setIsOpen(false);
      }}>
      <ul
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}>
        {dataTypesMetadata.flatMap((item, index) => {
          if (!item.definition) return [];
          return (
            <li
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}>
              <Typography variant="body" color="text-black" weight="bold">
                {item.frontendPresentableName}
              </Typography>
              <Typography variant="body" color="text-black">
                {item.definition}
              </Typography>
            </li>
          );
        })}
      </ul>
    </DialogLayout>
  );
}
