import { useCallback, useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import MacroGraphContext from './forecast-changes-history-context';
import { ForecastChangesHistoryRequest, ForecastChangesHistoryResponse } from './forecast-changes-history-types';
import forecastChangesHistoryService from './forecast-changes-history.service';

const MacroGraphProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [forecastHistory, setForecastHistory] = useState<ExternalData<ForecastChangesHistoryResponse>>({
    loading: false,
  });

  const getForecastHistory = useCallback(async (req: ForecastChangesHistoryRequest) => {
    setForecastHistory(makeExternalDataInitialData());
    const { promise, abort } = forecastChangesHistoryService.getForecastHistory(req);
    try {
      const res = await promise;
      setForecastHistory(makeExternalDataSuccessData(res));
    } catch (e: any) {
      setForecastHistory(makeExternalCallErrorData(e));
    }

    return abort;
  }, []);

  return (
    <MacroGraphContext.Provider value={{ forecastHistory, getForecastHistory }}>{children}</MacroGraphContext.Provider>
  );
};

export default MacroGraphProvider;
