import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import SummaryReportContext from './summary-report-context';
import { GetSummaryReportRequest } from './summary-report-types';
import SummaryReportService from './summary-report.service';

const SummaryReportProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const downloadSummaryReport = useCallback(async (request: GetSummaryReportRequest) => {
    const { promise, abort } = SummaryReportService.downloadSummaryReport(request);

    try {
      const res = await promise;
      const fileUrl = window.URL.createObjectURL(new Blob([res.data]));

      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', request.reportName);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(fileUrl);
    } catch (error: any) {
      const errorBlob = error.response.data;
      const errorText = await errorBlob.text();
      NormalAlert(JSON.parse(errorText).error || 'Error when trying to create the report');
    }
  }, []);

  const sendToEmailSummaryReport = useCallback(async (request: GetSummaryReportRequest) => {
    const { promise, abort } = SummaryReportService.sendToEmailSummaryReport(request);

    try {
      const res = await promise;
      NormalAlert('You will receive your email soon');
    } catch (error: any) {
      const axiosError = error as AxiosError;
      if (axiosError.response) NormalAlert(axiosError.response.data.error);
      else NormalAlert('Error when trying to send the email');
    }
  }, []);

  return (
    <SummaryReportContext.Provider
      value={{
        downloadSummaryReport,
        sendToEmailSummaryReport,
      }}>
      {children}
    </SummaryReportContext.Provider>
  );
};

export default SummaryReportProvider;
