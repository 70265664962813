import { Button, Typography } from '@amway/react-components';
import { Checkbox, FormControlLabel } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { TiDeleteOutline } from 'react-icons/ti';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import DataTypeSelector from '../../components/core/data-type-selector';
import MultiSelectCheckboxBusinessLines from '../../components/core/multi-select-checkbox-business-lines-input';
import MultiSelectCheckboxCategories from '../../components/core/multi-select-checkbox-categories';
import CorpCodeSelector from '../../components/core/multi-select-checkbox-corpcode-input';
import CurrencyFilterInput from '../../components/ui/currency-filter-option';
import DropdownBtn, { Item } from '../../components/ui/dropdownBtn';
import useCommon from '../../resources/common/common-hook';
import { DataTypeOption } from '../../resources/common/common-types';
import useSummaryReport from '../../resources/summary-report/summary-report-hook';
import { GetSummaryReportRequest } from '../../resources/summary-report/summary-report-types';
import { rollingMonthOptions, rollingTypeOptions } from '../macro-graph/utils';
import FcastSelector from './fcast-selector';
import './index.scss';
import {
  convertDataTypeSourceOptionsInRequest,
  convertItemInOption,
  DataTypeSourceOptions,
  defaultDataTypeSourceOption,
  showError,
  sortByOptions,
} from './util';

export const currencyOptions: Item[] = [
  { id: 'ACTUAL', label: 'U.S. $' },
  { id: 'Local', label: 'Local' },
  { id: 'ACTUAL BDGT', label: 'U.S $ Bdgt X RT Actual' },
  { id: 'BDGT', label: 'U.S. $ Bdgt X RT' },
  { id: 'DATED', label: 'U.S. $ Dated X RT' },
  { id: 'BDGT DT', label: 'U.S. $ Bdgt Dt X RT' },
];

export default function SummaryReportComponent() {
  const {
    countries,
    countriesTree,
    dataTypes,
    fcastSources,
    businessLines,
    categories,
    dataTypesMetadata,
    fetchAllCountries,
    fetchAllCountriesTree,
    fetchAllFcastSources,
    fetchAllDataTypes,
    fetchAllBusinessLines,
    fetchAllCategories,
    fetchAllDataTypesMetadata,
  } = useCommon();

  const { downloadSummaryReport, sendToEmailSummaryReport } = useSummaryReport();

  const [reportName, setReportName] = useState<string>('Summary Report');
  const [textToShow, setTextToShow] = useState<string>('');
  const [corpCodesSelected, setCorpCodesSelected] = useState<string[]>([]);
  const [clearCountries, setClearCountries] = useState(false);

  const [currencySelected, setCurrencySelected] = useState<Item>(currencyOptions[5]);
  const [currencyMonthSelected, setCurrencyMonthSelected] = useState<Item | undefined>();
  const [currencyYearSelected, setCurrencyYearSelected] = useState<Item | undefined>();

  const [dataTypeSourcesSelected, setDataTypeSourcesSelected] = useState<DataTypeSourceOptions[]>([
    defaultDataTypeSourceOption,
  ]);
  const [businessLinesSelected, setBusinessLinesSelected] = useState<string[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<string[]>([]);

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);

  const [selectedStartMonth, setSelectedStartMonth] = useState<string>(
    moment().year() + '-' + (1).toString().padStart(2, '0'),
  );
  const [selectedEndMonth, setSelectedEndMonth] = useState<string>(
    moment().year() + '-' + (12).toString().padStart(2, '0'),
  );

  const [isOnlyAnnualTotals, setIsOnlyAnnualTotals] = useState<boolean>(false);
  const [hasAnnualPercentageChanges, setHasAnnualPercentageChanges] = useState<boolean>(false);

  const [rollingMonthSelected, setRollingMonthSelected] = useState<Item | undefined>(rollingMonthOptions[0]);
  const [rollingTypeSelected, setRollingTypeSelected] = useState<Item | undefined>(rollingTypeOptions[2]);

  const [sortBySelected, setSortByOptions] = useState<Item>(sortByOptions[0]);

  const hasMonetaryDataType = useMemo(() => {
    return dataTypeSourcesSelected.some(dataTypeSource =>
      dataTypesMetadata.some(
        metaData =>
          dataTypeSource.dataType != null && metaData.dataType === dataTypeSource.dataType.value && metaData.isMonetary,
      ),
    );
  }, [dataTypeSourcesSelected, dataTypesMetadata]);

  useEffect(() => {
    if (countriesTree.length === 0) fetchAllCountriesTree();
    if (dataTypes.length === 0) fetchAllDataTypes();
    if (fcastSources.length === 0) fetchAllFcastSources();
    if (businessLines.length === 0) fetchAllBusinessLines();
    if (categories.length === 0) fetchAllCategories();
    if (dataTypesMetadata.length === 0) fetchAllDataTypesMetadata();
    if (countries.length === 0) fetchAllCountries();
  }, []);

  const hasErrorsInForm = () => {
    if (corpCodesSelected.length === 0) {
      return showError('Fill out the corp code field');
    }

    const allCodes = countries.map(country => country.id.toString());
    if (corpCodesSelected.some(code => !allCodes.includes(code))) {
      return showError("Some countries requested aren't in your country code list");
    }

    if (dataTypeSourcesSelected.length === 0) {
      return showError('Fill out the data type + source fields al least one time');
    }
    if (reportName === '') {
      return showError('Fill out the report name field');
    }

    if (hasMonetaryDataType) {
      const currencyType = currencySelected.id.toString();

      if (currencyType === 'DATED' || currencyType === 'BDGT DT') {
        if (currencyYearSelected === undefined) {
          return showError('You must select a year for the currency exchange rate');
        }

        const currencyYear = Number(currencyYearSelected.id);
        if (!currencyYear || isNaN(currencyYear)) {
          return showError('You must select a valid year for the currency exchange rate');
        }
      }

      if (currencyType === 'DATED') {
        if (currencyMonthSelected === undefined) {
          return showError('You must select a month for the currency exchange rate');
        }

        const currencyMonth = Number(currencyMonthSelected.id);
        if (!currencyMonth || isNaN(currencyMonth)) {
          return showError('You must select a valid month for the currency exchange rate');
        }
      }
    }

    for (let i = 0; i < dataTypeSourcesSelected.length; i += 1) {
      const dataTypeSource = dataTypeSourcesSelected[i];
      if (
        dataTypeSource.dataType == null ||
        dataTypeSource.dataType.value == '' ||
        dataTypeSource.fcastSource == null ||
        dataTypeSource.fcastSource == undefined
      ) {
        return showError('You must fill out all data type and fcast source fields');
      }
    }

    return false;
  };

  const prepareData = () => {
    const req: GetSummaryReportRequest = {
      countryCodes: corpCodesSelected,
      countriesSelected: textToShow,
      dataTypeSourceRequests: convertDataTypeSourceOptionsInRequest(dataTypeSourcesSelected),
      businessLines: businessLinesSelected,
      categories: categoriesSelected,
      rollingType: rollingTypeSelected!.id.toString(),
      rollingMonth: Number(rollingMonthSelected!.id),
      annualTotalOnly: isOnlyAnnualTotals,
      annualPercentageChanges: hasAnnualPercentageChanges,
      currencyOptions: {
        type: currencySelected.id.toString(),
        year: currencyYearSelected ? Number(currencyYearSelected.id) : null,
        month: currencyMonthSelected ? Number(currencyMonthSelected.id) : null,
      },
      reportName: reportName + '.xlsx',
      dateInterval: {
        startYear: moment(selectedStartMonth).year(),
        startMonth: moment(selectedStartMonth).month() + 1,
        endYear: moment(selectedEndMonth).year(),
        endMonth: moment(selectedEndMonth).month() + 1,
      },
      orderBy: sortBySelected.id.toString(),
    };

    return req;
  };

  const handleDownload = async () => {
    if (hasErrorsInForm()) {
      return;
    }

    const req = prepareData();
    NormalAlert('Preparing this report may take a while');

    setIsLoadingDownload(true);
    await downloadSummaryReport(req);
    setIsLoadingDownload(false);
  };

  const handleEmail = async () => {
    const req = prepareData();

    if (req === null) {
      return;
    }

    setIsLoadingEmail(true);
    await sendToEmailSummaryReport(req);
    setIsLoadingEmail(false);
  };

  const clearFilters = () => {
    setClearCountries(!clearCountries);
    setReportName('');

    setCurrencySelected(currencyOptions[1]);
    setCurrencyMonthSelected(undefined);
    setCurrencyYearSelected(undefined);

    setDataTypeSourcesSelected([defaultDataTypeSourceOption]);
    setBusinessLinesSelected([]);
    setCategoriesSelected([]);
  };

  return (
    <Container>
      <Card>
        <Card.Body>
          <Typography variant="heading">Summary Report</Typography>
          <div className="filter-daily-report">
            <Row>
              <Col>
                <Button
                  onClick={() => {
                    setDataTypeSourcesSelected([
                      ...dataTypeSourcesSelected,
                      dataTypeSourcesSelected[dataTypeSourcesSelected.length - 1],
                    ]);
                  }}>
                  Add data type + source
                </Button>
              </Col>
            </Row>
            <Row className="all-data-type-source-selected fancy-scrollbar">
              {dataTypeSourcesSelected.map((dataTypeSource, index) => (
                <Row key={index}>
                  <Col>
                    <Typography fontSize="10px" color="detail-gray">
                      Data Type
                    </Typography>
                    <DataTypeSelector
                      value={dataTypeSource.dataType}
                      multiple={false}
                      items={dataTypes}
                      handleSingleChange={item => {
                        const updatedList = dataTypeSourcesSelected.map((dataTypeSource, i) =>
                          i === index ? { ...dataTypeSource, dataType: item as DataTypeOption } : dataTypeSource,
                        );
                        setDataTypeSourcesSelected(updatedList);
                      }}
                    />
                  </Col>
                  <Col className="select-fcast-source-summary">
                    <Typography fontSize="10px" color="detail-gray">
                      Fcast Source
                    </Typography>
                    <FcastSelector
                      items={convertItemInOption(fcastSources)}
                      value={
                        dataTypeSource.fcastSource != null
                          ? {
                              value: dataTypeSource.fcastSource.id.toString(),
                              label: dataTypeSource.fcastSource.label,
                            }
                          : null
                      }
                      onChange={item => {
                        const updatedList = dataTypeSourcesSelected.map((dataTypeSource, i) =>
                          i === index
                            ? {
                                ...dataTypeSource,
                                fcastSource: item !== null ? { id: item.value, label: item.label } : null,
                              }
                            : dataTypeSource,
                        );
                        setDataTypeSourcesSelected(updatedList);
                      }}
                    />
                  </Col>
                  <Button disabled={dataTypeSourcesSelected.length === 1} className="remove-data-type-source-request">
                    <TiDeleteOutline
                      size={30}
                      color="#003b46"
                      onClick={() => {
                        setDataTypeSourcesSelected([
                          ...dataTypeSourcesSelected.slice(0, index),
                          ...dataTypeSourcesSelected.slice(index + 1),
                        ]);
                      }}
                    />
                  </Button>
                </Row>
              ))}
            </Row>

            <Row>
              <Typography fontSize="10px" color="detail-gray">
                Corp Codes
              </Typography>
              <CorpCodeSelector
                selectedCorpCodes={corpCodesSelected}
                setCorpCodesSelected={setCorpCodesSelected}
                setTextToShow={setTextToShow}
                clear={clearCountries}
              />
            </Row>
            <Row>
              <Col>
                <Typography fontSize="10px" color="detail-gray">
                  Business Lines
                </Typography>
                <MultiSelectCheckboxBusinessLines
                  selectedBusinessLines={businessLinesSelected}
                  setSelectedBusinessLines={setBusinessLinesSelected}
                  disabled={categoriesSelected.length > 0}
                />
              </Col>
              <Col>
                <Typography fontSize="10px" color="detail-gray">
                  Categories
                </Typography>
                <MultiSelectCheckboxCategories
                  selectedCategories={categoriesSelected}
                  setSelectedCategories={setCategoriesSelected}
                  disabled={businessLinesSelected.length > 0}
                />
              </Col>
            </Row>
            <div className="select-star-end-months">
              <Typography fontSize="10px" color="detail-gray">
                Start/End
              </Typography>
              <Form.Control
                type="month"
                id="monthInput"
                className="input"
                value={selectedStartMonth}
                onChange={e => setSelectedStartMonth(e.target.value)}
              />
              <Form.Control
                type="month"
                id="monthInput"
                className="input"
                value={selectedEndMonth}
                onChange={e => setSelectedEndMonth(e.target.value)}
              />
            </div>
            <Row>
              <Col>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isOnlyAnnualTotals}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.checked;
                        setIsOnlyAnnualTotals(value);
                        if (!value) {
                          setHasAnnualPercentageChanges(false);
                        }
                      }}
                    />
                  }
                  label="Annual Totals Only"
                />
              </Col>
              <Col>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!isOnlyAnnualTotals}
                      checked={hasAnnualPercentageChanges}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setHasAnnualPercentageChanges(e.target.checked)
                      }
                    />
                  }
                  label="Annual % Change"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Typography fontSize="10px" color="detail-gray">
                  Rolling Month
                </Typography>
                <DropdownBtn
                  id="choose-rolling-month-dropdown"
                  styleType="filter"
                  placeholder="Select"
                  items={rollingMonthOptions}
                  value={rollingMonthSelected}
                  onClick={item => {
                    if (item) {
                      setRollingMonthSelected(item);
                    }
                  }}
                  disabled={rollingTypeSelected && rollingTypeSelected.id === 'Cumulative'}
                />
              </Col>
              <Col>
                <Typography fontSize="10px" color="detail-gray">
                  Rolling Type
                </Typography>
                <DropdownBtn
                  id="choose-rolling-type-dropdown"
                  styleType="filter"
                  placeholder="Select"
                  items={rollingTypeOptions}
                  value={rollingTypeSelected}
                  onClick={item => {
                    if (item) {
                      setRollingTypeSelected(item);
                    }
                  }}
                />
              </Col>
            </Row>
            {hasMonetaryDataType ? (
              <CurrencyFilterInput
                currencyOptions={currencyOptions}
                currencySelected={currencySelected}
                setCurrencySelected={setCurrencySelected}
                currencyMonthSelected={currencyMonthSelected}
                setCurrencyMonthSelected={setCurrencyMonthSelected}
                currencyYearSelected={currencyYearSelected}
                setCurrencyYearSelected={setCurrencyYearSelected}
              />
            ) : (
              <></>
            )}
            <Row>
              <Col>
                <Typography fontSize="10px" color="detail-gray">
                  Sort By
                </Typography>
                <DropdownBtn
                  id="choose-rolling-type-dropdown"
                  styleType="filter"
                  placeholder="Select"
                  items={sortByOptions}
                  value={sortBySelected}
                  onClick={item => {
                    if (item) {
                      setSortByOptions(item);
                    }
                  }}
                />
              </Col>
            </Row>
            <div>
              <Typography fontSize="10px" color="detail-gray">
                Report name:
              </Typography>
              <Form.Control
                className="input"
                value={reportName}
                onChange={e => setReportName(e.target.value)}
                maxLength={50}
              />
            </div>
            <div style={{ display: 'flex', marginTop: '12px', justifyContent: 'space-between' }}>
              <Button onClick={clearFilters} disabled={isLoadingDownload || isLoadingEmail}>
                Clear
              </Button>
              <Button onClick={handleEmail} loading={isLoadingEmail} disabled={isLoadingDownload || isLoadingEmail}>
                Email
              </Button>
              <Button
                onClick={handleDownload}
                loading={isLoadingDownload}
                disabled={isLoadingDownload || isLoadingEmail}>
                Download
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}
