import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { Item } from '../../components/ui/dropdownBtn';
import { apiWrapper } from '../../config/api-wrapper';
import { DataTypeMetadata, GroupedOption, MultiSelectCheckboxOption } from './common-types';

class CommonService {
  public getAllDataTypes(): WithAbortFn<Promise<GroupedOption[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/common/data-types').then(res => res.data),
      abort: source.cancel,
    };
  }

  public getAllYearOptions(): WithAbortFn<Promise<Item[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/common/year-options').then(res => res.data),
      abort: source.cancel,
    };
  }

  public getAllDataTypesMetadata(): WithAbortFn<Promise<DataTypeMetadata[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/common/data-types/metadata').then(res => res.data),
      abort: source.cancel,
    };
  }

  public getAllBusinessLines(): WithAbortFn<Promise<Item[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/common/business-line').then(res => res.data),
      abort: source.cancel,
    };
  }

  public getAllExchangeRateYearOptions(): WithAbortFn<Promise<Item[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/common/exchange-year-options').then(res => res.data),
      abort: source.cancel,
    };
  }

  public fetchAllFcastSources(): WithAbortFn<Promise<Item[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/common/forecast-snapshots').then(res => res.data),
      abort: source.cancel,
    };
  }

  public getAllCategories(): WithAbortFn<Promise<Item[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/common/categories').then(res => res.data),
      abort: source.cancel,
    };
  }

  public getAllCountries(): WithAbortFn<Promise<{ name: string; code: string }[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/common/countries').then(res => res.data),
      abort: source.cancel,
    };
  }

  public getAllCountriesTree(): WithAbortFn<Promise<MultiSelectCheckboxOption[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/common/countries/tree').then(res => res.data),
      abort: source.cancel,
    };
  }

  public getAllExchangeRates(): WithAbortFn<Promise<{ name: string; value: string }[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/common/exchange-rate').then(res => res.data),
      abort: source.cancel,
    };
  }
}

export default new CommonService();
