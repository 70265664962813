import { SidebarNav } from '@amway/react-components';
import { useMemo } from 'react';
import { BsGraphUpArrow } from 'react-icons/bs';
import { FaGlobeAmericas } from 'react-icons/fa';
import { FaFileImport, FaRegMoneyBill1 } from 'react-icons/fa6';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdHistory, MdOutlineTune } from 'react-icons/md';
import { TbReportAnalytics } from 'react-icons/tb';
import { authenticatedRoutes } from '../../../config/routes';
import { FeaturesCtxType } from '../../../resources/features/features-context';
import useFeatures from '../../../resources/features/features-hook';

type Props = {
  open: boolean;
  toggleMenu: VoidFunction;
  onLogout: () => void;
};

const links = [
  {
    to: '/macro-graph',
    label: 'Macro Graph',
    eventKey: 'macro-graph',
    IconComponent: BsGraphUpArrow,
  },
  {
    to: '/maintenance',
    label: 'Maintenance',
    eventKey: 'main-maintenance',
    IconComponent: IoSettingsOutline,
    subLinks: [
      {
        to: '/maintenance/macro-maintenance',
        label: 'Macro Maintenance',
        eventKey: 'macro-maintenance',
        IconComponent: IoSettingsOutline,
      },
      {
        to: '/maintenance/dist-cost-forecast-generator',
        label: 'Dist Cost Forecast Generator',
        eventKey: 'dist-cost-forecast-generator',
        IconComponent: IoSettingsOutline,
      },
    ],
  },
  {
    to: '/reporting',
    label: 'Reporting',
    eventKey: 'reporting',
    IconComponent: TbReportAnalytics,
    subLinks: [
      {
        to: '/reporting/daily',
        label: 'Daily',
        eventKey: 'daily',
        IconComponent: TbReportAnalytics,
      },
      {
        to: '/reporting/summary',
        label: 'Summary',
        eventKey: 'summary',
        IconComponent: TbReportAnalytics,
      },
      {
        to: '/reporting/ranking',
        label: 'Ranking',
        eventKey: 'ranking',
        IconComponent: TbReportAnalytics,
      },
    ],
  },
  {
    to: '/import-forecast',
    label: 'Import forecast',
    eventKey: 'import-forecast',
    IconComponent: FaFileImport,
  },
  {
    to: '/forecast-changes-history',
    label: 'Forecast Changes History',
    eventKey: 'forecast-changes-history',
    IconComponent: MdHistory,
  },

  {
    to: '/exchange-rate-admin',
    label: 'Exchange Rate',
    eventKey: 'exchange-rate',
    IconComponent: FaRegMoneyBill1,
  },
  {
    to: '/admin/corp-code-admin',
    label: 'Corp Code Admin',
    eventKey: 'corp-code-admin',
    IconComponent: FaGlobeAmericas,
  },
  {
    to: '/scenario-forecast',
    label: 'Scenario Forecast',
    eventKey: 'scenario-forecast',
    IconComponent: MdOutlineTune,
  },
];

const filterAllowedLinks = (hasFeature: FeaturesCtxType['hasFeature']) => {
  return links.filter(link => {
    const linkRoute = authenticatedRoutes.find(route => route.path === link.to)!;

    return hasFeature(linkRoute.feature);
  });
};

export default function SideNavComponent({ onLogout, toggleMenu, open }: Props) {
  const { hasFeature } = useFeatures();
  const allowedLinks = useMemo(() => filterAllowedLinks(hasFeature), [hasFeature]);

  return (
    <SidebarNav
      open={open}
      onLogout={onLogout}
      toggleMenu={toggleMenu}
      allowedLinks={allowedLinks}
      hasSublinks={true}
    />
  );
}
