import { CellProps, TypeColumn } from '@inovua/reactdatagrid-community/types';
import moment from 'moment';
import {
  EditedValue,
  MaintenanceTableDataTypeColumn,
  MaintenanceTableResponse,
  MaintenanceTableRow,
} from '../../resources/maintenance/maintenance-types';

const selectDataTypeWidth = (dataTypeLenth: number) => {
  if (dataTypeLenth > 30) return 370;

  if (dataTypeLenth > 19) return 270;

  return 170;
};

const renderHeader = (
  column: MaintenanceTableDataTypeColumn,
  lockedCells: string[],
  editedValues: EditedValue[],
  isEditingValue: boolean,
): TypeColumn => {
  return {
    name: column.dataType,
    header: column.columnName,
    defaultWidth: selectDataTypeWidth(column.columnName.length),
    editable: (_editValue, cellProps) =>
      !lockedCells.includes(`${cellProps?.data.id},${cellProps?.id}`) && !isEditingValue,
    render: ({ cellProps }: { cellProps: CellProps }) => {
      const isLocked = lockedCells.includes(`${cellProps?.data.id},${cellProps?.id}`);
      const isEdited = editedValues.some(
        editedValue =>
          editedValue.yrMo === formatDate(`${cellProps.data.year}-${cellProps.data.month}`) &&
          cellProps.id === editedValue.dataType &&
          cellProps.data.corp === editedValue.countryCd,
      );

      return (
        <div style={{ position: 'relative' }}>
          {!isLocked && (
            <div
              style={{
                position: 'absolute',
                top: '-16px',
                right: '-15px',
                zIndex: 30,
                width: '15px',
                height: '15px',
                backgroundColor: 'var(--primary)',
                rotate: '45deg',
              }}
            />
          )}
          <span
            style={{
              color: isEdited ? 'green' : 'var(--black)',
              fontWeight: isEdited ? 'bold' : 'normal',
            }}>
            {cellProps.value !== '' ? Number(Number(cellProps.value).toFixed(2)).toLocaleString() : 0}
          </span>
        </div>
      );
    },
  };
};

export const corpReferHeaders = (
  lockedCells: string[],
  editedValues: EditedValue[],
  isEditingValue: boolean,
  columns: MaintenanceTableDataTypeColumn[],
): TypeColumn[] => [
  { defaultLocked: true, name: 'id', header: 'Id', defaultVisible: false, type: 'number' },
  { name: 'corp', header: 'Corp', editable: false, defaultVisible: false },
  { name: 'year', header: 'Year', editable: false, defaultVisible: false },
  { name: 'month', header: 'Month', editable: false, defaultVisible: false },
  ...columns.map((column, index) => renderHeader(column, lockedCells, editedValues, isEditingValue)),
];

export const formatRows = (response: MaintenanceTableResponse) => {
  return response.allRows.map(row => {
    const formattedRow: { [key: string]: any } = {
      id: `${row.corp},${formatDate(`${row.year}-${row.month}`)}`,
      corp: row.corp,
      year: row.year,
      month: row.month,
    };

    response.allDataTypes.forEach((dataTypeColumn, index) => {
      formattedRow[dataTypeColumn.dataType] = row.otherValues[index] || 0;
    });

    return formattedRow;
  });
};

export const getCellId = (row: MaintenanceTableRow, columnName: string) => {
  return `${row.corp},${row.year},${row.month},${columnName}`;
};

export const formatDate = (date: string) => {
  return Number(moment(date).format('YYYYMM'));
};

const formatHeaderName = (header: string) => {
  return header.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
};
