import { Button, LogoLoading, Typography } from '@amway/react-components';
import { ItemId, TreeItem } from '@atlaskit/tree';
import { useCallback, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { ConfirmGeneralAction } from '../../components/core/alert-card/alert-cards';
import useCountryCodeAdmin from '../../resources/country-code-admin/country-code-admin-hook';
import {
  CountryCodeAdminTree,
  CountryCodeRelationship,
} from '../../resources/country-code-admin/country-code-admin-types';
import countryCodeAdminService from '../../resources/country-code-admin/country-code-admin.service';
import { AddCountryForm } from './add-country-form';
import DragAndDropCorpCodeHierarchy from './drag-and-drop-component';
import './index.scss';

export default function CountryCodeAdmin() {
  const { countryCodeAdminTree, fetchCountryCodeAdminTree, setCountryCodeAdminTree } = useCountryCodeAdmin();
  const [initialFetch, setInitialFetch] = useState<boolean>(false);
  const [itemsEditedIds, setItemsEditedIds] = useState<string[]>([]);
  const [allCountriesToUpdateInfo, setAllCountriesToUpdateInfo] = useState<CountryCodeRelationship[]>([]);

  const [show, setShow] = useState(false);
  const [itemAdded, setItemAdded] = useState<TreeItem | undefined>(undefined);

  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [allItemsIdAdded, setAllItemsIdAdded] = useState<ItemId[]>([]);
  const [allItemsIdToDelete, setAllItemsIdToDelete] = useState<number[]>([]);

  const handleCreateNewCountry = useCallback(
    (newCountry: CountryCodeRelationship) => {
      if (countryCodeAdminTree) {
        const data = countryCodeAdminTree[0];
        data.children.unshift({
          ...newCountry,
          children: [],
        } as CountryCodeAdminTree);

        setItemAdded({
          id: newCountry.id.toString(),
          children: [],
          hasChildren: false,
          isExpanded: true,
          isChildrenLoading: false,
          data: {
            title: newCountry.name,
            isTemplate: false,
            countryCodeRelationship: newCountry,
          },
        });
        setCountryCodeAdminTree([data]);
        setAllItemsIdAdded(prev => [...prev, newCountry.id]);
      }
      setItemsEditedIds(prev => [...prev, newCountry.id.toString()]);
      setAllCountriesToUpdateInfo(prev => [...prev, newCountry]);
      setShow(false);
    },
    [countryCodeAdminTree, setCountryCodeAdminTree],
  );

  const handleResetButton = useCallback(() => {
    setItemAdded(undefined);
    setAllItemsIdAdded([]);
    setAllItemsIdToDelete([]);
    setItemsEditedIds([]);
    setAllCountriesToUpdateInfo([]);
    fetchCountryCodeAdminTree();
  }, [fetchCountryCodeAdminTree]);

  const saveChanges = useCallback(async () => {
    setIsSavingChanges(true);
    try {
      await countryCodeAdminService.saveCountryCodeRelationships({
        allToDelete: allItemsIdToDelete,
        allToSave: allCountriesToUpdateInfo,
      });
    } catch {}
    setIsSavingChanges(false);
    handleResetButton();
  }, [handleResetButton, allItemsIdToDelete, allCountriesToUpdateInfo]);

  const handleSaveChanges = useCallback(() => {
    ConfirmGeneralAction(
      'Do you really want to save the changes?',
      'Changes saved successfully',
      'Error saving changes, please try again.',
      saveChanges,
    );
  }, [saveChanges]);

  useEffect(() => {
    if (!initialFetch) {
      fetchCountryCodeAdminTree();
      setInitialFetch(true);
    }
  }, []);

  return (
    <Container>
      <Card>
        <Card.Body>
          <Row>
            <Typography variant="heading">Corp Code Admin</Typography>
            <Typography weight="bold" color="text-gray">
              Here you can modify the data about the Corp Code and the Markets
            </Typography>
          </Row>
          <Row>
            <Col>
              <Button
                onClick={() => {
                  setShow(true);
                }}
                disabled={countryCodeAdminTree.length === 0 || isSavingChanges}>
                Add country/market
              </Button>
            </Col>
            <Col>
              <Button onClick={handleResetButton} disabled={countryCodeAdminTree.length === 0 || isSavingChanges}>
                Reset
              </Button>
            </Col>
            <Col>
              <Button
                onClick={handleSaveChanges}
                disabled={countryCodeAdminTree.length === 0 || isSavingChanges}
                loading={isSavingChanges}>
                Save all changes
              </Button>
            </Col>
          </Row>
          {countryCodeAdminTree.length > 0 ? (
            <AddCountryForm
              show={show}
              handleClose={() => setShow(false)}
              handleSubmit={handleCreateNewCountry}
              currentData={countryCodeAdminTree}
            />
          ) : (
            <></>
          )}
          <Row>
            {initialFetch ? (
              countryCodeAdminTree.length > 0 ? (
                <Row>
                  <DragAndDropCorpCodeHierarchy
                    data={countryCodeAdminTree}
                    itemsEditedIds={itemsEditedIds}
                    setItemsEditedIds={setItemsEditedIds}
                    allItemsIdAdded={allItemsIdAdded}
                    allCountriesToUpdateInfo={allCountriesToUpdateInfo}
                    setAllCountriesToUpdateInfo={setAllCountriesToUpdateInfo}
                    itemAdded={itemAdded}
                    setAllItemsToDelete={setAllItemsIdToDelete}
                    isSavingChanges={isSavingChanges}
                  />
                </Row>
              ) : (
                <LogoLoading />
              )
            ) : (
              <></>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
