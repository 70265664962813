import { useEffect } from 'react';

type UseClickOutsideType = (ref: React.RefObject<HTMLElement>, handler: VoidFunction) => void;

const useClickOutside: UseClickOutsideType = (ref, handler) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handler, ref]);
};

export default useClickOutside;
