import DialogLayout from '../../../../../../components/core/dialog-layout';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function PercentageChangeInfoModal({ isOpen, setIsOpen }: Props) {
  return (
    <DialogLayout
      open={isOpen}
      title="Percentage Change Info"
      scapeActionText="Close"
      scapeActionHandler={() => {
        setIsOpen(false);
      }}>
      <p>The “% Change” will refer to the same period of the previous year, and only that year</p>
    </DialogLayout>
  );
}
