import { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import './index.scss';

export interface Item {
  id: string | number;
  label: string;
}

export type Props = {
  id: string;
  styleType?: 'default' | 'filter';
  required?: boolean;
  disabled?: boolean;
  none?: boolean;
  label?: string;
  placeholder: string;
  items: Item[];
  value?: Item;
  onClick: (item?: Item | null) => void;
};

export default function DropdownBtn({
  id,
  styleType = 'default',
  required,
  disabled,
  none,
  label,
  placeholder,
  items = [],
  value,
  onClick,
}: Props) {
  const [selected, setSelected] = useState<Item | undefined | null>();
  const itemRefs = useRef<(HTMLAnchorElement | null)[]>([]); // Ref array to store dropdown item refs

  const handleClick = useCallback(
    (item?: Item | null) => {
      setSelected(item);
      onClick(item);
    },
    [onClick],
  );

  useEffect(() => {
    if (items.length === 0) {
      setSelected(null);
    } else if (items.length === 1) {
      setSelected(items[0]);
    }
  }, [items]);

  useEffect(() => {
    if (items.length > 1 && selected) {
      const updatedSelected = items.find(({ id }) => id === selected.id);

      if (updatedSelected) {
        setSelected(updatedSelected);
      } else {
        setSelected(null);
      }
    }
  }, [items, selected]);

  useEffect(() => {
    if (value?.id) {
      setSelected(value);
    }
  }, [value]);

  // Scroll to the selected item when dropdown is opened
  const scrollToSelectedItem = useCallback(
    (isOpen: boolean) => {
      if (isOpen && selected) {
        // Delay the scroll to make sure the dropdown is fully open
        setTimeout(() => {
          const selectedItemIndex = items.findIndex(item => item.id === selected.id);
          if (selectedItemIndex !== -1 && itemRefs.current[selectedItemIndex]) {
            itemRefs.current[selectedItemIndex]?.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }
        }, 0);
      }
    },
    [items, selected],
  );

  return (
    <Form.Group id={id} className={'single-select-dropdown ' + styleType}>
      {label && (
        <Form.Label htmlFor={id} className={required ? 'required' : ''}>
          {label}
        </Form.Label>
      )}
      <DropdownButton
        id={`${id}-button`}
        className={selected ? 'selected' : ''}
        disabled={disabled}
        title={selected?.label ?? placeholder}
        onToggle={scrollToSelectedItem} // Trigger scrolling when dropdown is toggled
      >
        {none && !required && (
          <Dropdown.Item
            eventKey="none"
            id="dropdown-item-none"
            className="text-truncate"
            onClick={() => handleClick(null)}>
            None
          </Dropdown.Item>
        )}
        {items.map((item, i) => (
          <Dropdown.Item
            key={item.id}
            eventKey={item.id}
            id={`dropdown-item-${i}`}
            className={`text-truncate ${item.id === selected?.id ? 'active' : ''}`}
            onClick={() => handleClick(item)}
            ref={(el: HTMLAnchorElement | null) => (itemRefs.current[i] = el)} // Assign ref to each item
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </Form.Group>
  );
}
