import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiOutlineFormatPainter, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaRegSave } from 'react-icons/fa';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { LuBookMarked, LuPencil } from 'react-icons/lu';
import { VscRepoClone } from 'react-icons/vsc';
import { useReactToPrint } from 'react-to-print';
import Swal from 'sweetalert2';
import { NormalAlert } from '../../../components/core/alert-card/alert-cards';
import useCommon from '../../../resources/common/common-hook';
import useMacroGraph from '../../../resources/macro-graph/macro-graph-hook';
import macroGraphService from '../../../resources/macro-graph/macro-graph.service';
import { exportToExcel } from '../utils';
import CreateSnapshotModal from './create-snapshot-modal';
import './index.scss';

interface Props {
  targetRef: MutableRefObject<HTMLDivElement | null>;
  shouldShowTabs: { [key: string]: boolean };
  setShouldShowTabs: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
}

const Toolbar: React.FC<Props> = ({ targetRef, shouldShowTabs, setShouldShowTabs }) => {
  const [showSubmenuOptions, setShowSubmenuOptions] = useState(false);
  const {
    graphData,
    chartStyle,
    chartDataIsDirty,
    setChartDataIsDirty,
    updateRealTimeTableData,
    browserByCorpCodeCurrent,
  } = useMacroGraph();
  const { dataTypesMetadata } = useCommon();
  const submenuRef = useRef<HTMLDivElement>(null);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [openCreateSnapshotModal, setOpenCreateSnapshotModal] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
    documentTitle: `macro_forecast_${new Date().toISOString()}.pdf`,
  });

  const changeShowTab = (tabId: string) => {
    const currentValue = shouldShowTabs[tabId];
    const updatedIsToShowTabs: { [key: string]: boolean } = {};

    if (currentValue) {
      updatedIsToShowTabs[tabId] = false;
    } else {
      for (const k in shouldShowTabs) {
        updatedIsToShowTabs[k] = false;
      }
      updatedIsToShowTabs[tabId] = true;
    }

    setShouldShowTabs(updatedIsToShowTabs);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (submenuRef.current && !submenuRef.current.contains(event.target as Node)) {
        setShowSubmenuOptions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSaveChanges = () => {
    Swal.fire({
      text: '',
      icon: 'warning',
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#71baff',
      confirmButtonText: 'Save',
      denyButtonText: 'Save and hold % changes',
      cancelButtonText: 'Close',
    }).then(async result => {
      if (result.isConfirmed || result.isDenied) {
        await saveChanges(!result.isConfirmed)
          .then(() => NormalAlert('Changes saved successfully'))
          .catch(() => NormalAlert('Error saving changes, please try again.'));
      }
    });
  };

  const saveChanges = async (isToHoldPercentageChanges: boolean) => {
    setIsSavingChanges(true);
    await macroGraphService.persistValues({ isToHoldPercentageChanges });

    if (isToHoldPercentageChanges) {
      await updateRealTimeTableData();
    }
    setIsSavingChanges(false);
    setChartDataIsDirty(false);
  };

  return (
    <div className="toolbar">
      <div className="options">
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Save changes</Tooltip>}>
          <button disabled={!chartDataIsDirty || isSavingChanges} onClick={handleSaveChanges}>
            {isSavingChanges ? (
              <AiOutlineLoading3Quarters className="loading-icon" size={24} />
            ) : (
              <FaRegSave size={24} />
            )}
          </button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Create Snapshot</Tooltip>}>
          <button onClick={() => setOpenCreateSnapshotModal(true)}>
            <VscRepoClone size={24} />
          </button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Theme</Tooltip>}>
          <button
            onClick={() => {
              changeShowTab('theme');
              setShowSubmenuOptions(false);
            }}>
            <AiOutlineFormatPainter size={24} />
          </button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Graph Lines</Tooltip>}>
          <button
            onClick={() => {
              changeShowTab('input-data');
              setShowSubmenuOptions(false);
            }}>
            <LuPencil size={24} />
          </button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Notes</Tooltip>}>
          <button
            onClick={() => {
              changeShowTab('notes');
              setShowSubmenuOptions(false);
            }}>
            <LuBookMarked size={24} />
          </button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>More options</Tooltip>}>
          <button onClick={() => setShowSubmenuOptions(!showSubmenuOptions)}>
            <HiOutlineDotsVertical size={24} />
          </button>
        </OverlayTrigger>
      </div>

      {showSubmenuOptions ? (
        <div className="submenu" ref={submenuRef}>
          <button
            onClick={() => {
              if (
                graphData &&
                graphData.data &&
                (graphData.data.graphs.length > 0 || graphData.data.comparisonLines.length > 0)
              ) {
                exportToExcel(graphData.data, chartStyle, browserByCorpCodeCurrent, dataTypesMetadata);
              } else {
                NormalAlert('There is no data to export');
              }
              setShowSubmenuOptions(false);
            }}>
            Export as XLS
          </button>
          <button
            onClick={() => {
              if (
                graphData &&
                graphData.data &&
                (graphData.data.graphs.length > 0 || graphData.data.comparisonLines.length > 0)
              ) {
                handlePrint();
              } else {
                NormalAlert('There is no data to export');
              }
              setShowSubmenuOptions(false);
            }}>
            Export as PDF
          </button>
        </div>
      ) : (
        <></>
      )}
      <CreateSnapshotModal isOpen={openCreateSnapshotModal} close={() => setOpenCreateSnapshotModal(false)} />
    </div>
  );
};

export default Toolbar;
