// NEW DESIGN

export interface ITotalValues {
  value: number;
  percentage: number | null;
}

export interface MacroGraphChartLineRequest {
  id: number;
  dataType: string;
  fcastSource: string;
  countriesCodes: string[];
  businessLine?: string[]; //optional
  category?: string[]; //optional
  changeToPercentage: false;
  rollingType: string;
  rollingMonth: number;
  scale: string;
  show12MonthRollingAvg: boolean;
  graphAs: ChartTypes;
  currencyOptions: {
    year: number;
    month: number;
    type: string;
  };
  dateInterval: {
    startYear: number;
    endYear: number;
    startMonth: number;
    endMonth: number;
  };
}

export const initialSelectedCells: Record<number, Record<string, boolean>> = Array.from({ length: 30 }).map(() => ({}));

export const emptyMacroGraphChartLine: MacroGraphChartLineRequest = {
  id: 1,
  dataType: '',
  fcastSource: '',
  countriesCodes: [],
  changeToPercentage: false,
  rollingType: 'TotalBackward',
  rollingMonth: 1,
  scale: '',
  show12MonthRollingAvg: false,
  graphAs: 'line',
  currencyOptions: {
    year: new Date().getFullYear(),
    month: 0,
    type: 'BDGT DT',
  },
  dateInterval: {
    startYear: new Date().getFullYear() - 3,
    endYear: new Date().getFullYear(),
    startMonth: 1,
    endMonth: 12,
  },
};

export interface IComparisonLine {
  id: number;
  allToOne: boolean;
  line1: number;
  line2: number;
  type: string;
}

export interface IMacroGraphRequest {
  style: string;
  calendar: string;
  lines: MacroGraphChartLineRequest[];
  hasDraftValues: boolean;
  comparisonLines: IComparisonLine[];
  browserByCorpCode?: {
    show: boolean;
    lineNumber: number;
  };
}

export interface Row {
  yearMonth: number;
  value: number;
  isLocked: boolean;
}

export interface Line {
  title: string;
  dataType?: string;
  rows: Row[];
  totalValues: { year: string; total: ITotalValues }[];
  yearMonthDraft: string[];

  changedToPercentage: boolean;
  countryCodes: string[];
  months12RollingAvgData: boolean;
  monthsRollingAvg: Row[];
}

export interface Graph {
  browserByCorpCode: boolean;
  editable: boolean;
  lines: Line[];
}

interface ComparisonLine {
  title: string;
  rows: Row[];
  editable: boolean;
}

export interface IMacroGraphResponse {
  percentageChangeText: string;
  totalAvgText: string;
  graphs: Graph[];
  comparisonLines: ComparisonLine[];
}

export type ChartTypes =
  | 'line'
  | 'bar'
  | 'stacked-bar'
  | 'percentage-bar'
  | 'area'
  | 'stacked-area'
  | 'percentage-area';

export interface IChartStyle {
  styles: string;
  calendars: string;

  lineConfigurations: ILineConfiguration[];

  graphOptions: {
    showTabulation: boolean;
    scaleResults: boolean;
    thickLines: boolean;
    browseByCorpCode: boolean;
    browseByCorpCodeLine: number;
  };

  lineColors: {
    lineId: number;
    color: string;
  }[];

  comparisonLines: {
    type: string;
    allToOne: boolean;
  };
}
export interface ILineConfiguration {
  lineId: number;
  countryCodes: string[];
  dataType: string;
  currencyOptions: {
    year?: number;
    month?: number;
    type: string;
  };
  is12MonthRollingAvg: boolean;
  graphAs: ChartTypes;
  scale: string;
  showAsPercentage: boolean;
}

export const initialChartStyle: IChartStyle = {
  styles: 'trend',
  calendars: 'calendar',

  lineConfigurations: [],

  graphOptions: {
    showTabulation: true,
    scaleResults: false,
    thickLines: true,
    browseByCorpCode: false,
    browseByCorpCodeLine: 0,
  },

  lineColors: [],

  comparisonLines: {
    type: '',
    allToOne: false,
  },
};

export interface EditRawValuesRequest {
  countryCode: string;
  action: string;
  dataType: string;
  editedValue: number;
  allYearValues: Row[];
  lastYearTotalValue: number;
  scaled: boolean;
  currencyOptions: {
    year?: number;
    month?: number;
    type: string;
  };
}

export interface EditTotalValuesRequest {
  countryCode: string;
  dataType: string;
  newValue: Partial<ITotalValues>;
  selectedValues: EditTotalSelectedValue[];
  allYearValues: Row[];
  lastYearTotalValue: number;
  scaled: boolean;
  currencyOptions: {
    year?: number;
    month?: number;
    type: string;
  };
}

export interface EditTotalSelectedValue {
  yearMonth: number;
  action: string;
}

export interface EditTotalValuesResponse {
  newSelectedValues: Row[];
  newTotal: ITotalValues;
}

export interface LockUnlockFieldRequest {
  countryCode: string;
  yearMonths: number[];
  dataType: string;
  lock: boolean;
}

// SCENARIO FORECAST SCREEN
// List<YearScenario> china;
// List<YearScenario> ana;
// List<YearScenario> thailand;
// List<YearScenario> india;
// List<YearScenario> southKorea;
// List<YearScenario> japan;
// List<YearScenario> taiwan;
// List<YearScenario> malaysia;
// List<YearScenario> esan;
// List<YearScenario> ukraine;
// List<YearScenario> centralAsia;
// List<YearScenario> latam;
// List<YearScenario> hongKong;
// List<YearScenario> vietnam;
// List<YearScenario> singapore;
// List<YearScenario> philippines;
// List<YearScenario> indonesia;

// public class YearScenario {
//   private Integer year;
//   private Double total;
//   private Double yoy;
//   private Double ro;
//   private Double roYoy;
// }

export interface YearScenario {
  year: number;
  total: number;
  yoy: number;
  ro: number;
  roYoy: number;
}

export interface DefaultScenarios {
  china: YearScenario[];
  ana: YearScenario[];
  thailand: YearScenario[];
  india: YearScenario[];
  southKorea: YearScenario[];
  japan: YearScenario[];
  taiwan: YearScenario[];
  malaysia: YearScenario[];
  esan: YearScenario[];
  ukraine: YearScenario[];
  centralAsia: YearScenario[];
  latam: YearScenario[];
  hongKong: YearScenario[];
  vietnam: YearScenario[];
  singapore: YearScenario[];
  philippines: YearScenario[];
  indonesia: YearScenario[];
  totalExChina: YearScenario[];
  total: YearScenario[];
}

export interface ScenarioForecastResponse {
  allScenarios: DefaultScenarios;
  lastYearRevenueTotal: number;
}

export interface PersistValuesRequest {
  isToHoldPercentageChanges: boolean;
}
