import moment from 'moment';
import { ILineConfiguration } from '../../../resources/macro-graph/macro-graph-types';

export const formatDataTypeName = (dataType: string) => {
  return dataType
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatYAxis = (tick: number, maxValue: number) => {
  let formattedTick;

  // If the max value is in billions, format everything in billions
  if (maxValue >= 1000000000) {
    formattedTick = (tick / 1000000000).toFixed(2);
    return `${Number(formattedTick)}B`; // Removes trailing zeros
  }

  // If the max value is in millions, format everything in millions
  if (maxValue >= 1000000) {
    formattedTick = (tick / 1000000).toFixed(2);
    return `${Number(formattedTick)}M`; // Removes trailing zeros
  }

  // If the max value is in thousands, format everything in thousands
  if (maxValue >= 1000) {
    formattedTick = (tick / 1000).toFixed(2);
    return `${Number(formattedTick)}K`; // Removes trailing zeros
  }

  // If the max value is below thousands, return the raw tick value
  return Number(tick.toFixed(2)).toString(); // Remove unnecessary decimals
};

export const formatXAxisTrend = (tick: number) => {
  const year = ((tick / 100) % 100).toFixed(0);
  const monthNumber = tick % 100;
  const monthName = moment()
    .month(monthNumber - 1)
    .format('MMM');

  return monthName + ' ' + year;
};

export const toPercent = (decimal: number) => `${decimal * 100}%`;

export const getPercent = (value: number, total: number) => {
  const ratio = total > 0 ? value / total : 0;
  return `${(ratio * 100).toFixed(2)}%`;
};

export const getYAxisLabel = (lineConfigurations: ILineConfiguration[], scale: string) => {
  const result = Array.from(
    new Set(lineConfigurations.filter(line => line.scale === scale).map(line => line.dataType)),
  );
  if (result.length === 1) {
    return formatDataTypeName(result[0]);
  }
  return '';
};
