import { Button, Typography } from '@amway/react-components';
import { InfoRounded } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import DataTypeSelector from '../../components/core/data-type-selector';
import CorpCodeSelector from '../../components/core/multi-select-checkbox-corpcode-input';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import useCommon from '../../resources/common/common-hook';
import { DataTypeOption } from '../../resources/common/common-types';
import useForecastChangesHistory from '../../resources/forecast-changes-history/forecast-changes-history-hook';
import { ForecastChangesHistoryRequest } from '../../resources/forecast-changes-history/forecast-changes-history-types';
import DataTypesInfoModal from '../macro-graph/side-wrapper-tabs/input-data-tab/line-accordion-item/info-modals/data-types-info';
import HistoryTable from './history-table';
import './index.scss';

export default function ForecastChangesHistoryComponent() {
  const { countries, fetchAllCountries, dataTypes, fetchAllDataTypes, dataTypesMetadata, fetchAllDataTypesMetadata } =
    useCommon();

  const { forecastHistory, getForecastHistory } = useForecastChangesHistory();

  const [corpCodesSelected, setCorpCodesSelected] = useState<string[]>([]);
  const [dataTypeSelected, setDataTypeSelected] = useState<DataTypeOption | null>({
    value: 'dist_cost',
    label: 'Dist Cost',
  });
  const [showSystemUpdates, setShowSystemUpdates] = useState<boolean>(false);

  const [dataTypesInfoModalOpen, setDataTypesInfoModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [initialFetch, setInitialFetch] = useState<boolean>(false);

  const [forecastStartYearMonth, setForecastStartYearMonth] = useState<string>(
    moment().subtract(1, 'years').format('YYYY-MM'),
  );
  const [forecastEndYearMonth, setForecastEndYearMonth] = useState<string>(moment().add(1, 'years').format('YYYY-MM'));

  const [transactionStartYearMonth, setTransactionStartYearMonth] = useState<string>(
    moment().subtract(1, 'years').format('YYYY-MM-DD'),
  );
  const [transactionEndYearMonth, setTransactionEndYearMonth] = useState<string>(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    if (countries.length === 0) fetchAllCountries();
    if (dataTypes.length === 0) fetchAllDataTypes();
    if (dataTypesMetadata.length === 0) fetchAllDataTypesMetadata();
  }, []);

  const hasEmptyValues = () => {
    if (corpCodesSelected.length === 0 || dataTypeSelected === undefined) {
      NormalAlert('Fill in the corp code and data type fields');
      return null;
    }
    if (forecastStartYearMonth === '' || forecastEndYearMonth === '') {
      NormalAlert('Fill in the fields related to the forecast dates');
      return null;
    }
    if (transactionStartYearMonth === '' || transactionEndYearMonth === '') {
      NormalAlert('Fill in the fields related to the transaction dates');
      return null;
    }
  };

  const handleViewButtonCick = () => {
    if (hasEmptyValues() != null) {
      return null;
    }

    const startYearMonth = Number(forecastStartYearMonth.replace('-', ''));
    const endYearMonth = Number(forecastEndYearMonth.replace('-', ''));

    const req: ForecastChangesHistoryRequest = {
      countryCode: corpCodesSelected[0],
      dataType: dataTypeSelected!.value,
      forecastInterval: {
        startMonth: startYearMonth % 100,
        startYear: Math.floor(startYearMonth / 100),
        endMonth: endYearMonth % 100,
        endYear: Math.floor(endYearMonth / 100),
      },
      showSystemUpdates: showSystemUpdates,
      transactionStartDate: moment(transactionStartYearMonth, 'YYYY-MM-DD').utc().toDate(),
      transactionEndDate: moment(transactionEndYearMonth, 'YYYY-MM-DD').utc().toDate(),
    };

    setIsLoading(true);
    getForecastHistory(req);
    setIsLoading(false);
    setInitialFetch(true);
  };

  return (
    <Container>
      <Card>
        <Card.Body>
          <Typography variant="heading">Forecast Changes History</Typography>
          <div className="filter-forecast-changes">
            <div className="form">
              <Row>
                <Col>
                  <div style={{ marginBottom: '7px' }}>
                    <Typography fontSize="10px" color="detail-gray">
                      Corp Code
                    </Typography>
                  </div>
                  <CorpCodeSelector
                    selectedCorpCodes={corpCodesSelected}
                    setCorpCodesSelected={setCorpCodesSelected}
                    clear={true}
                    multi={false}
                  />
                </Col>
                <Col>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <Typography fontSize="10px" color="detail-gray">
                      Data Type
                    </Typography>
                    <div style={{ cursor: 'pointer' }} onClick={() => setDataTypesInfoModalOpen(true)}>
                      <InfoRounded />
                    </div>
                  </div>
                  <DataTypeSelector
                    value={dataTypeSelected}
                    multiple={false}
                    items={dataTypes}
                    handleSingleChange={item => {
                      setDataTypeSelected(item);
                    }}
                  />
                </Col>
                <Col style={{ maxWidth: '250px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showSystemUpdates}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setShowSystemUpdates(e.target.checked);
                        }}
                      />
                    }
                    label="Show System Updates"
                  />
                </Col>
                <Col>
                  <div className="legend">
                    <p>Legend:</p>
                    <div>
                      <p>
                        Insert: <span className="created">Value</span>
                      </p>
                      <p>
                        Update: <span className="updated">Value</span>
                      </p>
                      <p>
                        Delete: <span className="deleted">Value</span>
                      </p>
                      <p>
                        No Change: <span className="no-change">Value</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        In graph: <span className="graph">Value</span>
                      </p>
                      <p>
                        In maintenance: <span className="maintenance">Value</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        In file import: <span className="file-import">Value</span>
                      </p>
                      <p>
                        By SYSTEM: <span className="system">Value</span>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography fontSize="10px" color="detail-gray">
                    Forecast Start Date
                  </Typography>
                  <Form.Control
                    type="month"
                    id="forecastStartDate"
                    className="input"
                    value={forecastStartYearMonth}
                    onChange={e => setForecastStartYearMonth(e.target.value)}
                  />
                </Col>
                <Col>
                  <Typography fontSize="10px" color="detail-gray">
                    Forecast End Date
                  </Typography>
                  <Form.Control
                    type="month"
                    id="forecastEndDate"
                    className="input"
                    value={forecastEndYearMonth}
                    onChange={e => setForecastEndYearMonth(e.target.value)}
                  />
                </Col>
                <Col>
                  <Typography fontSize="10px" color="detail-gray">
                    Transaction Start Date
                  </Typography>
                  <Form.Control
                    type="date"
                    id="transactionStartDate"
                    className="input"
                    value={transactionStartYearMonth}
                    onChange={e => setTransactionStartYearMonth(e.target.value)}
                  />
                </Col>
                <Col>
                  <Typography fontSize="10px" color="detail-gray">
                    Transaction End Date
                  </Typography>
                  <Form.Control
                    type="date"
                    id="transactionEndDate"
                    className="input"
                    value={transactionEndYearMonth}
                    onChange={e => setTransactionEndYearMonth(e.target.value)}
                  />
                </Col>
              </Row>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }}>
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  className="button-view"
                  onClick={() => {
                    handleViewButtonCick();
                  }}>
                  View
                </Button>
              </div>
              <Row>
                <Col>
                  {initialFetch ? (
                    <ExternalDataRenderer
                      externalData={forecastHistory}
                      makeDataElement={data => {
                        return data.changes.length > 0 ? (
                          <HistoryTable forecastHistory={data} />
                        ) : (
                          <Typography variant="heading" className="text-center">
                            No data available
                          </Typography>
                        );
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* MODALS */}
      <DataTypesInfoModal isOpen={dataTypesInfoModalOpen} setIsOpen={setDataTypesInfoModalOpen} />
    </Container>
  );
}
