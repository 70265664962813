import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/theme/green-dark.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
